<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="800px"
      transition="dialog-bottom-transition">
      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">{{ modal_title }}</span>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-row class="align-center justify-center mb-4">
              <v-col md="11" class="elevation-0 pt-4 pb-4 mt-4" style="background: #f5f5f5">
                <v-stepper v-model="step" :items="steps" hide-actions :height="400">
                  <template v-slot:[`item.${1}`]>
                    <v-row class="justify-center align-center">
                      <v-col md="12" class="d-flex align-center justify-center">
                        <div class="text-center align-center">
                          <div class="mt-2 mb-2">
                            <v-label class="text-order-pay">Orden #{{ item.id }}</v-label>
                          </div>
                          <div class="mt-2 mb-2" v-if="item.order_quoting" >
                            <v-label class="text-total-pay">${{ parseFloat(item.order_quoting.price_agreed) }}</v-label>
                          </div>
                          <div v-if="item.order_quoting" class="mt-2">
                            <v-label class="subtext-order-pay">Inicia el pago de la orden, una vez realizado <br/>
                              <b>{{ item.order_quoting.contact_data.business_name }}</b>
                              colectara los envios para su <br/> posterior entrega.
                            </v-label>
                          </div>
                          <div class="mt-3">
                            <Button flat :loading="loading" text
                              class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
                              label="Pagar" @click="handlePay"></Button>                            
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:[`item.${2}`]>
                    <StatusMessage
                      :status="paymentStatus"
                      :title="statusTitle"
                      :message="statusMessage"
                      @handlePay="handlePay"

                      :loading="loading"
                    />
                  </template>
                 
                </v-stepper>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end pt-0">
            <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl" height="50"
              text @click="closeModal()" label="Cerrar" color="error"></Button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import StatusMessage from "./StatusMessage.vue";
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";

export default {
  mixins: [ValidMixin],
  components: { StatusMessage, Button },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
    statusTitle: String,
    statusMessage: String,
    paymentStatus: String,
    step_pay: {
      type: Number,
      default: 1
    },
    order_id: Number
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      name: "",     
      step: this.step_pay,
      item: {
        id: 0,
        code: "",
        creation_date: "",
        state: null,
        order_quoting: null
      },
      steps: [
        {
          title: "Pagar",
          content: "First-content",
          value: 1
        },
        {
          title: "Detalle",
          content: "Second-content",
          value: 2
        },
      ],
      defaultItem: null,
    };
  },

  watch: {
    dialog() {
      
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));

        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }

      }
    },
  },

  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    prev() {
      this.step--;
    },
    async next() {
      this.step++;
    },
    closeModal() {
      this.$emit('updateDialog', false);
      this.step = 1;
    },
    handlePay() {
      this.loading = true;

      const body = {
        order_quoting_id: this.item.order_quoting ? this.item.order_quoting.id : this.order_id
      }

      this.service
        .createPreference(body)
        .then((response) => {
          if (response.status) {
            window.location.href = response.result.preference_link
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.toast.error(response.message);
            } else {
              this.toast.error(response.msg);
            }
          }
        })
        .catch((response) => {
          this.toast.error(response.msg);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
};
</script>
