const STATE_PENDING = 1; //blue-grey pendiente 
const STATE_ON_THE_WAY = 2;//azul en camino 
const STATE_DELIVERED = 3;//verde entregado 
const STATE_NOT_DELIVERED = 4;//amarillo no entregado 
const STATE_RETURNED = 5;//naranja devuelto 
const STATE_INCOMPLETE = 6;//gris incompleto 

export default {
    STATE_PENDING: STATE_PENDING,
    STATE_ON_THE_WAY: STATE_ON_THE_WAY,
    STATE_DELIVERED: STATE_DELIVERED,
    STATE_NOT_DELIVERED: STATE_NOT_DELIVERED,
    STATE_RETURNED: STATE_RETURNED,
    STATE_INCOMPLETE: STATE_INCOMPLETE,
}
