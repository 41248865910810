const STATE_PENDING = "P";//Pendiente a aceptar

const STATE_ACCEPTED = "A";//Acepatada

const STATE_CHARGED = "C";//Cobrada 

const STATE_REJECTED = "R";//Rechazada 

const STATE_CANCELED = "X";//Cancelada

const STATE_DEFEATED = "V";//Vencida  

export default {
    STATE_PENDING: STATE_PENDING,
    STATE_ACCEPTED: STATE_ACCEPTED,
    STATE_CHARGED: STATE_CHARGED,
    STATE_REJECTED: STATE_REJECTED,
    STATE_CANCELED: STATE_CANCELED,
    STATE_DEFEATED: STATE_DEFEATED,
  };
  