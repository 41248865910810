<template>
  <div class="register-welcome">
    <div class="container-logo-image">
      <a href="/">
        <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="150" class="" />
      </a>
    </div>

    <v-row align="center" justify="center" class="h-100">
      <v-col md="12" class="pa-0">
        <div class="card-welcome card-finish rounded-0 h-100 bg m-auto">
          <div class="justify-content-center text-white text-center">
            <h2>Nueva Contraseña</h2>
          </div>
          <v-card class="custom-card-welcome text-left mt-5">
            <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="newPassword">
              <v-label>Contraseña</v-label>
              <v-text-field
                :rules="[rules.min8Length]"
                persistent-hint
                variant="outlined"
                v-model="formData.password"
                outlined
                :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              ></v-text-field>

              <v-label>Confirmar Contraseña</v-label>
              <v-text-field
                :rules="[rules.min8Length, confirmPasswordValidation(formData.password)]"
                persistent-hint
                variant="outlined"
                v-model="formData.password_confirmation"
                outlined
                :type="showPasswordConfirmation ? 'text' : 'password'"
                :append-inner-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append-inner="showPasswordConfirmation = !showPasswordConfirmation"
              ></v-text-field>
              <v-divider></v-divider>

              <Button :loading="loading" type="submit" class="bg-button-register w-100 btn text-capitalize text-white mt-3" rounded="xl" height="50" label="Nueva Contraseña"></Button>                         
            </v-form>
          </v-card>
          <div class="justify-content-center w-100 d-flex">
            <a href="/">
              <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="100" class="pt-10 mt-10" v-if="$vuetify.display.xs" />
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import { authService } from "@/libs/ws/Public/authService";
import Button from "@/components/Button.vue";

export default {
  name: "ForgotPassword",

  props: {},
  mixins: [ValidMixin],

  components: {Button},

  data: () => ({
    loading: false,
    formData: {
      password: "",
      password_confirmation: "",
    },
    showPassword: false,
    showPasswordConfirmation: false,
  }),

  methods: {
    confirmPasswordValidation(password) {
      return (value) => value === password || "Las contraseñas no coinciden.";
    },
    newPassword() {
      if (this.loading) return;

      var param = {
        recovery_token: this.$route.params.hash,
        password: this.formData.password,
        password_confirmation: this.formData.password_confirmation,
      };

      if (this.valid) {
        this.loading = false;

        authService
          .newPassword(param)
          .then((response) => {
            if (response.status) {
              this.$router.push("/login");
            } else {
              this.toast.error(response.msg);
            }
          })
          .catch((e) => {
            this.toast.error("Ocurrio un error al enviar el email");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted: () => {},
};
</script>
