<template>
  <div class="register-welcome">
    <div class="container-logo-image">
      <a href="/">
        <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="150" class="" />
      </a>
    </div>

    <v-row align="center" justify="center" class="h-100">
      <v-col md="12" class="pa-0">
        <div class="card-welcome rounded-0 h-100 bg m-auto">
          <div class="justify-content-center text-white text-center">
            <h2>¡Bienvenido!</h2>
            <p class="mb-5">¿Cómo deseas registrarte?</p>
          </div>

          <v-card class="custom-card-welcome">
            <Button class="bg-button-register w-100 btn text-capitalize text-white" rounded="xl" height="50" @click="$emit('nextStep')" label="Registrarme manualmente"></Button>

            <div class="container-text d-flex w-100 align-center">
              <span class="w-100"> </span>
              <p class="text-nowrap">o utilizando</p>
              <span class="w-100"> </span>
            </div>

            <v-col md="12" class="text-center p-0">
              <GoogleLogin :callback="callbackSocialLogin" popup-type="TOKEN">
                <button class="btn rounded-xl button-google">
                  <v-img class="float-start mt-1" :src="require('@/assets/images/google.svg')" width="15" height="15" />
                  Google
                </button>
              </GoogleLogin>
            </v-col>
          </v-card>
          <div class="justify-content-center w-100 d-flex">
            <a href="/">
              <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="100" class="pt-10 mt-10" v-if="$vuetify.display.xs" />
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { authService } from "@/libs/ws/Public/authService";
import Button from "@/components/Button.vue";

export default {
  name: "Welcome",
  props: {
    nextStep: Function,
    setUser: Function,
  },
  components: {Button},
  mounted: () => {},
  data: () => ({}),
  methods: {
    callbackSocialLogin(response) {
      this.getGoogleUserData(response.access_token);
    },
    getGoogleUserData(access_token) {
      authService
        .decodeProviderToken(access_token)
        .then((response) => {
          if (response.status) {
            var user = {
              name: response.result.name,
              email: response.result.email,
              access_token: access_token,
            };

            this.$emit("setUser", user);
            this.$emit("nextStep");
          } else {
            this.toast.error("Ocurrio un error al obtener los datos del usuario");
          }
        })
        .catch((e) => {
          console.log(e);
          this.toast.error("Ocurrio un error al obtener los datos del usuario");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
