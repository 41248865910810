import { wsService } from "../wsService";

const section = "meli_accounts";

function filter(param = { enable: true }) {
  return wsService.make_get_request(section + "/accounts");
}


function getClientId(){
  return wsService.make_get_request(section + "/client_id");
}

function authAccount(body){
  return wsService.make_post_request(section + "/auth", body);
}

function remove(param = {}) {
  const body = {
    account_id: param.id
  }
  return wsService.make_post_request(section + "/logout", body);
}

export const meliAccountsService = {
  section,
  filter, 
  authAccount,
  getClientId,
  remove
};
