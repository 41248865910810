import { wsService } from "../wsService";

const section = "rates";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}


function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function getAllZones(param = {}) {
  return wsService.make_post_request(section + "/zones", param);
}

function getAllZoneGroup() {
  return wsService.make_get_request(section +"/list_zone_groups");
}

export const ratesService = {
  section,
  filter,
  update,
  getAllZoneGroup,
  getAllZones
};
