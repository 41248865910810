<template>
  <div>
    <ForgotPassword v-if="step == 0" @hasStepPassword="hasStepPassword"></ForgotPassword>
    <SendEmail v-if="step == 1" :email="email"></SendEmail>
  </div>
</template>
<script>
import ForgotPassword from "./ForgotPassword.vue";
import SendEmail from "./SendEmail.vue";

export default {
  name: "ContainerRegister",
  components: {
    ForgotPassword,
    SendEmail,
  },
  data: () => ({
    step: 0,
    email: null,
  }),
  methods: {
    hasStepPassword(email) {
      this.step = this.step + 1;
      this.email = email;
    },
  },
  mounted: () => {},
};
</script>
