const SECRET_PHRASE = "ewgew54ew69gWEGwe*";

const SECTION_DASHBOARD = "dashboard";

const SECTION_MONITORING = "monitoring";

const SECTION_SHIPPING = "shippings";

const SECTION_INVOICING = "invoicing";

const SECTION_DRIVER = "drivers";

const SECTION_VEHICLE = "vehicle";

const SECTION_FEE = "fee";

const SECTION_COLLABORATORS = "collaborators";

const SECTION_ROLES = "roles";

const SECTION_MELI = "meli_accounts";

const SECTION_ORDERS = "orders"

const SECTION_QUOTINGS = "orders-quotings"

export default {
  SECTION_DASHBOARD: SECTION_DASHBOARD,
  SECRET_PHRASE: SECRET_PHRASE,
  SECTION_MONITORING: SECTION_MONITORING,
  SECTION_SHIPPING: SECTION_SHIPPING,
  SECTION_INVOICING: SECTION_INVOICING,
  SECTION_DRIVER: SECTION_DRIVER,
  SECTION_VEHICLE: SECTION_VEHICLE,
  SECTION_FEE: SECTION_FEE,
  SECTION_COLLABORATORS: SECTION_COLLABORATORS,
  SECTION_ROLES: SECTION_ROLES,
  SECTION_MELI: SECTION_MELI,
  SECTION_ORDERS: SECTION_ORDERS,
  SECTION_QUOTINGS:SECTION_QUOTINGS
};
