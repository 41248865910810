<template>
  <div class="row w-100 header">
    <div class="col-md-12 pe-0 me-0">
      <v-toolbar style="background: #042137" height="64" dark dense class="elevation-0">
        <v-tooltip bottom :open-delay="1000">
          <template v-slot:activator="{ props }">
            <v-app-bar-nav-icon v-if="$vuetify.display.mobile" v-bind="props" @click="handleSidebar"
              color="#ffffff"></v-app-bar-nav-icon>
          </template>
          <span>{{ $store.state.sidebar.open ? "Contraer Menu" : "Expandir Menu" }}</span>
        </v-tooltip>

        <div class="d-flex align-items-center justify-space-between w-100 ps-2 pe-2">
          <p class="text-white mb-0 ms-4 title-main">
            <Title :pages="$store.state.pageTitle.page_title"></Title>
          </p>
          <!-- <p class="mb-0 mr-2 text-white">{{ getUsername() }}</p> -->
          <div class="container-button-header">
            <v-tooltip bottom>

              <template v-slot:activator="{ props }">
                <v-btn icon v-bind="props" class="me-2">
                  <v-icon color="#ffffff">mdi-bell-outline</v-icon>
                </v-btn>
              </template>
              <span>Notificaciones</span>
            </v-tooltip>

            <v-menu>

              <template v-slot:activator="{ props }">
                <v-btn icon="mdi-account-circle" class="text-white" v-bind="props"></v-btn>

              </template>

              <v-card min-width="300" style="margin-top: 0.45rem">
                <v-list class="justify-content-center align-center d-flex container-profile">
                  <v-avatar color="surface-variant" size="80">
                    <v-icon v-if="profile_picture_url == null" color="#ffffff" class="justify-content-center d-flex img-avatar">mdi-account-circle</v-icon>
                    <v-img v-else :src="profile_picture_url" class="justify-content-center d-flex img-avatar"></v-img>

                  </v-avatar>

                </v-list>


                <v-list class="d-flex justify-content-between">
                  <v-list-item v-if="editProfile != 0">
                    <v-list-item-title class="pointer list-menu" @click="handleMiPerfil">Mi
                      Perfil</v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title v-on:click="openLogoutModal()" class="pointer list-menu">Cerrar
                      Sesión</v-list-item-title>
                  </v-list-item>
                </v-list>


              </v-card>
            </v-menu>

            <!-- </v-tooltip> -->
          </div>
        </div>
      </v-toolbar>

      <DialogYesNo :open="openConfiguration" title="Configura tu punto de recolección"
        message="Necesitamos que configures tu punto de recoleccion." @cancel="openConfiguration = false"
        @accept="handleGoToConfiguration" nameButtonNo="Cancelar" nameButtonYes="Ir a Configurar" />

      <ModalProfile v-if="editProfile == 2" :dialog="dialog" :service="service" :list="list"
        @updateDialog="updateDialog" />

      <ModalProfileSeller v-if="editProfile == 1" :dialog="dialog" :service="service" :list="list"
        @updateDialog="updateDialog" :startItem="selectedItemModalProfileSeller" :itemProfile="itemProfile" />

      <ModalProfileLogistic v-if="editProfile == 3" :dialog="dialog" :service="service" :list="list"
        @updateDialog="updateDialog" />

      <DialogYesNo :open="dialog_logout" title="Confirmar" message="¿Estas seguro que quieres cerrar sesion?"
        @cancel="dialog_logout = false" @accept="logoutConfirm" />
    </div>
  </div>
</template>

<script>
import { authService } from "@/libs/ws/Public/authService";
import { profileService } from "@/libs/ws/Public/profileService";
import ModalProfile from "./ModalProfile.vue"
import ModalProfileSeller from "./ModalProfileSeller.vue"
import ModalProfileLogistic from "./ModalProfileLogistic.vue"
import DialogYesNo from "./DialogYesNo.vue"
import Title from "./Title.vue"
import GenericMixin from "@/mixins/GenericMixin.vue";
import ConstantUserType from "@/constants/user_type";


export default {
  name: "App",
  components: { ModalProfile, ModalProfileSeller, DialogYesNo, Title,ModalProfileLogistic },
  mixins: [GenericMixin],

  data() {
    return {
      dialog_logout: false,
      dialog: false,
      page_title: "",
      service: profileService,
      editProfile: 0,
      openConfiguration: false,
      selectedItemModalProfileSeller: 0,
      profile_picture_url: null,
      itemProfile: {
        postal_code: null,
        lat: null,
        lng: null
      },
    };
  },
  mounted() {
    /* this.$i18n.locale = "en"; */
    this.handleEditProfile();
    this.handleOpenConfiguration();
    this.loadProfileImage();
  },
  methods: {

    handleEditProfile() {
      const appObject = localStorage.getItem("app_object");
      const objectParsed = JSON.parse(appObject);

      switch (objectParsed.user.user_type) {
        case ConstantUserType.SELLER:
          this.editProfile = 1;
          break;
        case ConstantUserType.LOGISTIC:
          this.editProfile = 3;
          break;
        default:
          this.editProfile = 2;
      }
    },


    loadProfileImage() {
            profileService
                .filter()
                .then((response) => {
                  this.profile_picture_url = response.result.profile_picture_url;
                })
                .catch((e) => {
                    console.log("service.load profile image error");
                    console.log(e);
                })
        },

    handleGoToConfiguration() {
      this.openConfiguration = false;
      this.updateDialog(true);
    },
    handleMiPerfil() {
      this.selectedItemModalProfileSeller = 0;
      this.updateDialog(true);
    },
    handleOpenConfiguration() {
      this.service
        .filter()
        .then((response) => {
          if (response.result.postal_code === null || response.result.lng === null || response.result.lat === null) {
            this.openConfiguration = true;
            this.selectedItemModalProfileSeller = 1;
            return;
          }

          this.openConfiguration = false;

        })
        .catch((e) => {
          console.log("service.load configuration error ");
          console.log(e);
        })


    },
    openLogoutModal() {
      this.dialog_logout = true;
    },
    handleSidebar() {
      if (window.innerWidth >= 1264) {
        this.$store.commit("setOpenSidebar", {
          open: !this.$store.state.sidebar.open,
        });
      } else {
        this.$store.commit("setOpenSidebar", {
          drawer: !this.$store.state.sidebar.drawer,
          open: true,
        });
      }
    },
    getUsername() {
      return localStorage.getItem("username");
    },
    logoutConfirm() {
      authService.logOut();
      this.$router.push("/login");
    },
    langSelector(name) {
      this.$i18n.locale = name;
      localStorage.setItem("lang", name);
      location.reload();
    },
  },
};
</script>
