<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="500px"
      transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <Button flat class="bg-button-register btn text-capitalize text-white me-1" :v-bind="props"
          @click="$emit('updateDialog', true)" rounded="xl" height="50" :label="'+ ' + button_name"></Button>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">

                <v-label>Nombre*</v-label>
                <v-text-field v-model="item.name" :disabled="disabled" density="compact" :rules="[rules.required]"
                  persistent-hint variant="outlined"></v-text-field>
                <v-label>Email</v-label>
                <v-text-field :disabled="disabled" density="compact" :rules="[rules.required, rules.email]"
                  persistent-hint variant="outlined" v-model="item.user.email"></v-text-field>
                <v-label>Contraseña</v-label>
                <v-text-field density="compact" :rules="[applyPasswordValidation ? rules.min8Length : null]"
                  persistent-hint variant="outlined" v-model="item.password" outlined
                  :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPassword = !showPassword" :type="showPassword ? 'text' : 'password'"
                  :disabled="disabled"></v-text-field>
                <v-label>Confirmar Contraseña</v-label>
                <v-text-field density="compact"
                  :rules="[applyPasswordValidation ? rules.min8Length : null, confirmPasswordValidation(item.password)]"
                  persistent-hint variant="outlined" v-model="item.password_confirmation" outlined
                  :type="showPasswordConfirmation ? 'text' : 'password'"
                  :append-inner-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="showPasswordConfirmation = !showPasswordConfirmation"
                  :disabled="disabled"></v-text-field>

                <v-label>Vehiculos</v-label>

                <v-combobox v-model="item.vehicle" density="compact" variant="outlined" name="vehicle_brand" item-title="vehicle_brand"
                  item-value="id" :items="vehicles" autocomplete="off" :disabled="disabled" :rules="[rules.requiredComboBox]"
                  clearable></v-combobox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl" height="50" text
              @click="$emit('updateDialog', false)" label="Cerrar" color="error"></Button>

            <Button flat :loading="loading" text type="submit"
              class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
              :label="formBtn" v-if="!disabled"></Button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { vehiclesService } from "@/libs/ws/Logistic/vehiclesService";


export default {
  mixins: [ValidMixin],
  components: { Button },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      name: "",
      item: {
        user: {
          id: 0,
          name: "",
          user: { email: ""},
          vehicle: { id: ""},
          password: "",
          password_confirmation: "",

        }

      },
      showPassword: false,
      showPasswordConfirmation: false,
      defaultItem: null,
      vehicles: [],
      applyPasswordValidation: false,

    };
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {

          this.item = JSON.parse(JSON.stringify(this.editItem));
          this.item.password = "";
          this.item.password_confirmation = "";
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
    item: {
      handler: function (newValue) {       
        if(newValue.id > 0 && newValue.password !== '') {          
          this.applyPasswordValidation = true
          return
        }

        if(newValue.id == 0) {
          this.applyPasswordValidation = true
          return
        }

        this.applyPasswordValidation = false
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.loadVehicles();
  },
  methods: {
    confirmPasswordValidation(password) {
      return (value) => value === password || "Las contraseñas no coinciden.";
    },
    loadVehicles() {

      const param = {}
      vehiclesService
        .filter(param)
        .then((response) => {
          this.vehicles = response.result.list;
        })
        .catch((e) => {
          console.log("service vehicle drivers error");

        })
    },
    createItem() {
      if (this.loading) return;

      
      if (this.valid) {
        this.loading = true;
        
        var data = {
          id: this.item.id,
          name: this.item.name,
          email: this.item.user.email,
          password: this.item.password,
          password_confirmation: this.item.password_confirmation,
          vehicle: this.item.vehicle,
          enable: true
        };
        
        if (this.item.id > 0) {
          // this.applyPasswordValidation = true;
          
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modificó correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creó correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
