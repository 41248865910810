<template>
  <div class="register-welcome">
    <div class="container-logo-image">
      <a href="/">
        <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="150" class="" />
      </a>
    </div>

    <v-row align="center" justify="center" class="h-100">
      <v-col md="12" class="pa-0">
        <div class="title-container justify-content-center text-center">
          <h2>Recupero de contraseña</h2>
        </div>
        <div class="card-welcome card-finish rounded-0 h-100 bg m-auto mt-5">
          <v-card class="custom-card-welcome text-center">
            <p>Te enviamos un correo a:</p>
            <p>
              <b>{{ email }}</b>
            </p>
          </v-card>
          <div class="justify-content-center w-100 d-flex">
            <a href="/">
              <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="100" class="pt-10 mt-10" v-if="$vuetify.display.xs" />
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SendEmail",

  props: {
    email: String,
  },

  components: {},

  data: () => ({}),

  methods: {},
  mounted: () => {},
};
</script>
