<template>
    <v-layout class="container-modal">
        <v-dialog :modelValue="dialog" max-width="750px" transition="dialog-bottom-transition">

            <v-card>
                <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="updateItem">
                    <v-card-title class="p-3 header-modal mb-4">
                        <span class="headline text-white">Mi Perfil</span>
                    </v-card-title>

                    <v-card-text>
                        <!-- START FORM DIALOG -->
                        <div class="row">
                            <div class="col-md-6">
                                <v-label>Nombre y apellido</v-label>
                                <v-text-field density="compact" :rules="[rules.required]" persistent-hint
                                    variant="outlined" v-model="item.name"></v-text-field>
                                <v-label>Email</v-label>
                                <v-text-field density="compact" :rules="[rules.required, rules.email]" persistent-hint
                                    variant="outlined" v-model="item.email" autocomplete="off"></v-text-field>


                                <v-label>Teléfono</v-label>
                                <v-text-field density="compact" :rules="[rules.required]" persistent-hint
                                    variant="outlined" v-model="item.phone"></v-text-field>
                                <v-label>CUIT</v-label>
                                <v-text-field density="compact" :rules="[rules.required]" persistent-hint
                                    variant="outlined" v-model="item.cuit"></v-text-field>
                            </div>
                            <div class="col-md-6">
                                <v-label>Razón Social</v-label>
                                <v-text-field density="compact" :rules="[rules.required]" persistent-hint
                                    variant="outlined" v-model="item.business_name" required></v-text-field>
                                <v-label>Dirección</v-label>
                                <v-text-field density="compact" :rules="[rules.required]" persistent-hint
                                    variant="outlined" v-model="item.address"></v-text-field>

                                <v-label>Ciudad</v-label>
                                <v-text-field density="compact" :rules="[rules.required]" persistent-hint
                                    variant="outlined" v-model="item.city"></v-text-field>

                                <v-label>Provincia</v-label>
                                <v-combobox density="compact" :rules="[rules.requiredComboBox]" persistent-hint
                                    item-title="name" item-value="id" v-model="item.provincia" :items="provincias"
                                    variant="outlined" autocomplete="off"></v-combobox>

                            </div>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <Button flat type="button" class="btn text-capitalize text-white me-1 btn-modal" rounded="xl"
                            height="50" text @click="$emit('updateDialog', false)" label="Cerrar"
                            color="error"></Button>

                        <Button flat :loading="loading" text type="submit"
                            class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
                            label="Editar" v-if="!disabled"></Button>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { profileService } from "@/libs/ws/Public/profileService";
import { provincesService } from "@/libs/ws/Public/provincesService";

export default {
    mixins: [ValidMixin],
    components: { Button },
    props: {
        service: Object,
        dialog: Boolean,
        updateDialog: Function,
    },
    data() {
        return {
            show: false,
            disabled: false,
            loading: false,
            name: "",
            item: {
                email: "",
                name: "",
                phone: "",
                address: "",
                city: "",
                cuit: "",
                business_name: "",
                provincia: ""
            },
            provincias: [],

        };
    },

    mounted() {
        this.loadProvincias()
        this.loadProfile()
    },
    methods: {
        loadProvincias() {
            provincesService
                .getAllProvince()
                .then((response) => {
                    this.provincias = response.result.list;
                })
                .catch((e) => {
                    console.log("service.load provinces profile error");
                    console.log(e);
                });
        },
        loadProfile() {
            profileService
                .filter()
                .then((response) => {
                    this.item.email = response.result.user.email;
                    this.item.name = response.result.name;
                    this.item.phone = response.result.phone;
                    this.item.address = response.result.address;
                    this.item.city = response.result.city;
                    this.item.cuit = response.result.cuit;
                    this.item.business_name = response.result.business_name;
                    this.item.provincia = response.result.provincia != null && { id: response.result.provincia.id, name: response.result.provincia.name };
                })
                .catch((e) => {
                    console.log("service.load profile error");
                    console.log(e);
                })
        },
        updateItem() {
            if (this.loading) return;


            if (this.valid) {
                this.loading = true;

                var data = this.item;


                this.service
                    .update(data)
                    .then((response) => {
                        if (response.status) {
                            this.toast.success("El perfil se modificó correctamente.");
                            this.$emit("updateDialog", false);
                            this.$emit("loadList");
                        } else {
                            if (Object.prototype.hasOwnProperty.call(response, "message")) {
                                this.toast.error(response.message);
                            } else {
                                this.toast.error(response.msg);
                            }
                        }
                    })
                    .catch((response) => {
                        this.toast.error(response.msg);
                    })
                    .finally(() => {
                        this.loading = false;
                    });


            }


        },
    },
};
</script>
