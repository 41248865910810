<template>
    <div>
        <v-dialog :modelValue="open" max-width="500">
            <v-card>
                <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="addOrders">

                    <v-card-title class="p-3 header-modal mb-4">
                        <span class="headline text-white">{{ title }}</span>
                    </v-card-title>

                    <v-card-text>

                        <v-combobox density="compact" :rules="[rules.requiredComboBox]" persistent-hint
                            item-title="code" item-value="id" v-model="selectedOrder" :items="orders" variant="outlined"
                            autocomplete="off"></v-combobox>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="red darken-1" text @click="$emit('cancel')"> {{ nameButtonNo }} </v-btn>

                        <v-btn color="green darken-1" text type="submit" :loading="loading"> {{ nameButtonYes }}
                        </v-btn>
                    </v-card-actions>
                </v-form>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import { ordersService } from '@/libs/ws/Seller/ordersService';
import { shippingService } from "@/libs/ws/Seller/shippingService";
import ShippingState from "@/constants/shipping_state";

export default {
    mixins: [ValidMixin],
    props: {
        title: String,
        message: String,
        open: Boolean,
        cancel: Function,
        accept: Function,
        loadList: Function,
        nameButtonNo: {
            default: "No",
            type: String
        },
        nameButtonYes: {
            default: "Si",
            type: String
        },
        
        selected: Array
    },
    data: () => ({
        loading: false,
        orders: [],
        selectedOrder: null,

    }),
    mounted() {
        this.loadOrders()
    },
    methods: {
        loadOrders() {
            ordersService
                .filter()
                .then((response) => {
                    if(response.status) {
                        this.orders = response.result.list;
                    }
                })
                .catch((e) => {
                    console.log("service.load shipping orders error");
                    console.log(e);
                });
        },
        addOrders() {
            if (this.valid) {
                this.loading = true;
                const body = {
                    id: this.selectedOrder.id,
                    shippings_arr: this.selected.filter((item) => item.state.id != ShippingState.STATE_INCOMPLETE).map(item => ({ id: item.id }))
                }

                ordersService
                    .add(body)
                    .then((response) => {
                        if (response.status) {
                            this.toast.success("La Orden se agrego correctamente.");
                            this.$emit('cancel');
                            this.$emit("loadList", shippingService, { no_order: 1 })
                        } else {
                            if (Object.prototype.hasOwnProperty.call(response, "message")) {
                                this.toast.error(response.message);
                            } else {
                                this.toast.error(response.msg);
                            }
                        }
                    })
                    .catch((response) => {
                        this.toast.error(response.msg);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    }

};
</script>
