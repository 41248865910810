<script>
// definir un objeto mixin
export default {
  data() {
    return {
      valid: false,
      rules: {
        notRequired: (value) => true,
        required: (value) => !!value || "El campo es obligatorio.",
        requiredComboBox: (value) => (value != null && Object.prototype.hasOwnProperty.call(value, "id")) || "Debe seleccionar una opcion.",
        max3Length: (value) => value.length <= 3 || "El campo debe tener maximo 3 caracteres.",
        min8Length: (value) => value.length >= 8 || "El campo debe tener minimo 8 caracteres.",
        email: (value) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(value) || value == "" || "El campo email no tiene un formato valido.",
        integer: (value) => /[0-9-]+/.test(value) || value == "" || "El campo debe ser numerico.",
        requiredTerms: (value) => !!value || "Debe aceptar los terminos y condiciones.",
        latitud: (value => /^-?([1-8]?[0-9](\.[0-9]+)?|90(\.0+)?)$/.test(value) || value == "" || "El campo latitud no tiene un formato valido."),
        longitud: (value => /^-?([1]?[0-7]?[0-9](\.[0-9]+)?|180(\.0+)?)$/.test(value) || value == "" || "El campo longitud no tiene un formato valido."),
        cuit: (value) => /^[0-9]{11}$/.test(value) || "El CUIT no tiene un formato válido (11 dígitos sin guiones).",
        minBasePrice: (value, basePrice) => parseFloat(value) > parseFloat(basePrice) || `El valor debe ser mayor a ${basePrice}.`,
      },
    };
  },
  created: function () { },
  computed: {},
  mounted() { },
  methods: {},
};
</script>
