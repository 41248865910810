<template>
    <v-layout class="container-modal">
        <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="800px"
            transition="dialog-bottom-transition">
            <v-card>
                <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
                    <v-card-title class="p-3 header-modal mb-4">
                        <span class="headline text-white">{{ item.zone_group.name }}</span>
                    </v-card-title>
                    <v-card-text class="pb-0" style="min-height: 550px;">
                        <!-- START FORM DIALOG -->
                        <v-row class="align-center justify-center mb-4">
                            <v-col md="11" class="elevation-0 pt-4 pb-4" style="background: #f5f5f5">
                                <v-stepper v-model="step" :items="steps" hide-actions style="min-height: 500px;">
                                    <template v-slot:[`item.${1}`]>
                                        <v-form ref="form_step1"
                                            class="align-items-center justify-content-center d-flex"
                                            style="min-height: 290px">
                                            <div class="content-card-fee">
                                                <v-label>Tarifa</v-label>
                                                <v-text-field v-model="item.price" density="compact" persistent-hint
                                                    variant="outlined"
                                                    :messages="'Monto minimo ' + item.zone_group.base_price"
                                                    class="input-fee"
                                                    :rules="[rules.required, rules.minBasePrice(item.price, item.zone_group.base_price)]"></v-text-field>


                                            </div>
                                        </v-form>
                                    </template>
                                    <template v-slot:[`item.${2}`]>
                                        <div class="align-items-start justify-content-center d-flex"
                                            style="min-height: 290px">
                                            <div class="content-card-fee">
                                                <v-label>Zonas</v-label>
                                                <v-combobox density="compact" persistent-hint item-title="name"
                                                    item-value="id" v-model="selectZone" :items="zones"
                                                    variant="outlined" autocomplete="off"
                                                    @update:modelValue="handleZoneChange"></v-combobox>
                                                <div v-if="item.zones.length">
                                                    <v-chip v-for="zone in item.zones" :key="zone.id" class="chip-fee"
                                                        closable @click:close="removeZone(zone.id)">
                                                        {{ zone.name }}
                                                    </v-chip>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <v-card-actions>
                                        <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button"
                                            rounded="xl" height="50" text @click="closeModal()" label="Cerrar"
                                            color="error"></Button>
                                        <div class="w-100">
                                            <v-stepper-actions v-if="step === 1" prev-text="Atrás" next-text="Siguiente"
                                                @click:next="nextForm()" @click:prev="closeModal()" class="pb-0">
                                            </v-stepper-actions>
                                                <div class="justify-content-between d-flex pb-0 pe-4">
                                                    <Button v-if="step == 2" flat class="btn text-capitalize text-white me-1 mb-0 btn-modal"
                                                        type="button" rounded="xl" height="50" text @click="prev()"
                                                        label="Atras" color="purple"></Button>
                                                
                                                    <Button v-if="step == 2" type="submit" class="bg-button-register btn mb-0 text-capitalize text-white" rounded="xl" height="50"
                                                        label="Configurar" @click="createItem" :loading="loading"></Button>
                                                </div>
                                        </div>
                                    </v-card-actions>
                                </v-stepper>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { ratesService } from "@/libs/ws/Logistic/ratesService";

export default {
    mixins: [ValidMixin],
    components: { Button },
    props: {
        service: Object,
        button_name: String,
        modal_title: String,
        page_title: String,
        formBtn: String,
        dialog: Boolean,
        updateDialog: Function,
        loadList: Function,
        editItem: Object,
        basePrice: Number,
        loading_submit: Boolean
    },
    data() {
        return {
            show: false,
            disabled: false,
            loading: false,
            step: 1,
            zones: [],
            steps: [
                {
                    title: "Tarifa"
                },
                {
                    title: "Zonas"
                }
            ],
            item: {
                zone_group: {
                    id: 0,
                    name: "",
                    base_price: "",
                    min_km: "",
                    max_km: ""
                },
                zones: [],
                price: null,
            },
            selectZone: [],
            defaultItem: null,
        };
    },
    watch: {
        dialog() {
            if (this.dialog) {
                this.disabled = this.formBtn === "";
                if (this.$refs.form) {
                    this.$refs.form.resetValidation();
                }
                if (this.editItem != null) {
                    this.item = JSON.parse(JSON.stringify(this.editItem));
                } else {
                    if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
                }
            }
        },
        selectZones(newZones) {
            this.item.zones = newZones;
        }
    },
    mounted() {
        this.defaultItem = JSON.parse(JSON.stringify(this.item));
        this.loadZones();
    },
    methods: {
        prev() {
            this.step--;
        },
        async nextForm() {
            const { valid } = await this.$refs.form_step1.validate();
            if (valid) {
                this.step++;
            }
        },
        closeModal() {
            this.$emit('updateDialog', false);
            this.step = 1;
        },
        loadZones() {
            ratesService
                .getAllZones()
                .then((response) => {
                    this.zones = response.result.list;
                })
                .catch((e) => {
                    console.log("service.load zones rates error");
                    console.log(e);
                });
        },
        handleZoneChange() {
            const selectedZone = this.zones.find(zone => zone.id === this.selectZone.id);
            if (this.item.zones && !this.item.zones.some(zone => zone.id === selectedZone.id)) {
                this.item.zones.push(selectedZone);
            }
        },
        removeZone(zone) {
            this.item.zones = this.item.zones.filter(selectedZone => selectedZone.id !== zone);
        },
        createItem() {
            if (this.loading) return;
            if (this.valid) {
                this.loading = true;

                var data = [{
                    zone_group: {
                        id: this.item.zone_group.id
                    },
                    price: this.item.price,
                    zones: this.item.zones.map(zone => zone.id)
                }]

                this.service
                    .update(data)
                    .then((response) => {
                        if (response.status) {
                            this.toast.success("La tarifa de " + this.item.zone_group.name + " se modificó correctamente.");
                            this.$emit("updateDialog", false);
                            this.$emit("loadList");
                        } else {
                            this.toast.error(response.message || response.msg);
                        }
                    })
                    .catch((response) => {
                        this.toast.error(response.msg);
                    })
                    .finally(() => {
                        this.step = 1;
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
