import { wsService } from "../wsService";

const section = "orders-quotings";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}
function getByCode(id) {  
  return wsService.make_get_request(section + "/get_by_code?code=" + id);
}

function states() {
    return wsService.make_get_request("orders/states-quotings");
}

function accept(param) {
    return wsService.make_post_request(section + "/accept", param);
}

function reject(param) {
    return wsService.make_post_request(section + "/reject", param);
}

export const ordersQuotingService = {
  section,
  filter,
  accept,
  reject,
  states,
  getByCode
};
