<template>
  <div>
    <Title :pages="[{ icon: 'person', page: page_title.toUpperCase() }]"></Title>
  </div>
</template>

<script>
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
export default {
  name: "Dashboard",
  components: { Title },
  data() {
    return { page_title: "Dashboard" };
  },
  methods: {},
};
</script>
