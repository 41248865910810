<template>
  <div>
    <section v-if="pages.length == 1" class="content-header line-header d-flex ">
      <div class="container-title d-flex">
        <font-awesome-icon :icon="pages[0].icon" />
        <h1 class="text-white mb-0 ms-4 title-main">
          {{ pages[0].page }}
        </h1>
      </div>
    </section>

    <section v-if="pages.length > 1" class="content-header line-header d-flex ">
      <ol class="breadcrumb mb-0">
        <li v-bind:key="index" v-for="(item, index) in pages">
          <a v-if="index == 0" v-on:click="$router.back()" class="container-item-blue">
            <font-awesome-icon class="item-blue" :icon="item.icon" />
            <span class="item-blue ml-2">{{ item.page }}</span></a>
          <a v-if="index != 0 && index != pages.length - 1 && pages.length > 2" class="container-item-blue">
            <span class="item-blue">{{ item.page }}</span>
          </a>
          <a style="cursor:default !important" v-if="index != 0 && index == pages.length - 1">
            <span class="item"> <span class="ms-2 me-2 item-separator"> > </span> {{ item.page }} </span>
          </a>
        </li>
      </ol>
    </section>
  </div>
</template>
<script>
export default {
  name: "Title",
  props: {
    pages: Array,
  },
  data: () => ({}),
  methods: {},
};
</script>
