<template>
  <div class="register d-flex align-items-center">
    <div class="container-logo-image">
      <a href="/">
        <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="150" class="" />
      </a>
    </div>
    <v-row>
      <v-col md="12">
        <div class="card rounded-0 h-100 bg m-auto">
          <div class="justify-content-center text-white text-center">
            <h2>Registrate</h2>
          </div>

          <v-card class="custom-card">
            <v-row>
              <v-col cols="12" sm="12" md="6" class="container-custom-card container-custom-card-left">
                <h3 class="title-web mb-4">Realizar envíos</h3>
                <v-row>
                  <v-col cols="12" sm="12" md="8" justify="center">
                    <div>
                      <v-img :src="require('@/assets/images/register/icon-seller.svg')" contain max-width="120" class="img-mobile mt-3 mb-3" v-if="$vuetify.display.smAndDown" />
                    </div>
                    <h3 class="title-mobile mb-4">Realizar envíos</h3>
                    <p class="pr-md-3 description">Soy vendedor y necesito realizar envíos rápidos a mis clientes.</p>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" v-if="$vuetify.display.mdAndUp">
                    <v-img :src="require('@/assets/images/register/icon-seller.svg')" contain max-width="150" class="img-web" />
                  </v-col>
                </v-row>
                <Button class="bg-button-register btn text-capitalize text-white" rounded="xl" height="50" @click="$emit('setUserType', UserType.SELLER)" label="Soy vendedor"></Button>

              </v-col>

              <v-col cols="12" sm="12" md="12" v-if="$vuetify.display.smAndDown">
                <v-divider></v-divider>
              </v-col>

              <v-divider vertical v-else></v-divider>

              <v-col cols="12" sm="12" md="6" class="container-custom-card container-custom-card-right">
                <h3 class="title-web mb-4">Convertite en repartidor</h3>

                <v-row>
                  <v-col cols="12" sm="12" md="8" justify="center">
                    <div>
                      <v-img :src="require('@/assets/images/register/icon-deliveryman.svg')" max-width="120" class="img-mobile mt-3 mb-3" v-if="$vuetify.display.smAndDown" />
                    </div>
                    <h3 class="title-mobile mb-4">Convertite en repartidor</h3>
                    <p class="pr-md-3 description">Soy una empresa de transporte o un conductor particular y deseo realizar entregas.</p>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" v-if="$vuetify.display.mdAndUp">
                    <v-img :src="require('@/assets/images/register/icon-deliveryman.svg')" contain max-width="150" class="img-web" />
                  </v-col>
                </v-row>
                <div class="mb-4 mb-md-0">
                  <Button class="bg-button-register btn text-capitalize text-white me-1" rounded="xl" height="50" @click="$emit('setUserType', UserType.LOGISTIC)" label="Soy empresa"></Button>
                  <Button class="bg-button-register btn text-capitalize text-white ms-1" rounded="xl" height="50" @click="$emit('setUserType', UserType.DRIVER_INDEPENDENT)" label="Soy individuo"></Button>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserType from "@/constants/user_type";
import Button from "@/components/Button.vue";

export default {
  name: "Register",
  props: {
    setUserType: Function,
  },
  data: () => ({
    UserType: UserType,
  }),
  components: {Button},
  methods: {
    setUserTypeRegister(type) {
      this.$emit("setUserType", type);
    }
  },
  mounted: () => {},
};
</script>
