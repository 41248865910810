import { wsService } from "../wsService";

const section = "vehicles";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function getAllTypesVehicle() {
  return wsService.make_get_request("vehicle_types");
}

export const vehiclesService = {
  section,
  filter,
  remove,
  create,
  update,
  getAllTypesVehicle
};
