<template>
  <div></div>
</template>

<script>
import { authService } from "@/libs/ws/Public/authService";
export default {
  name: "ValidateEmail",
  components: {},
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.validateEmail();
  },
  methods: {
    validateEmail() {
      if (this.loading) return;
      var param = {
        user_id: this.$route.params.user_id,
        hash: this.$route.params.hash,
      };
      this.loading = true;

      authService
        .validateEmail(param)
        .then((response) => {
          if (response.status) {
            this.$router.push("/");
          } else {
            this.toast.error(response.msg);
          }
        })
        .catch((e) => {
          console.log(e);
          this.toast.error("Ocurrio un error al validar el email");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
