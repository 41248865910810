<template>
 <v-card rounded="0" theme="dark" class="bg-yegoo mx-auto sidebar d-flex flex-column" elevation="0">
  <div>
    <div style="height: 64px" class="d-flex justify-content-center align-items-center">
      <p class="title-user mb-0">Hola, {{ user }}</p>
    </div>
  </div>

  <div class="mt-4 flex-grow-1 overflow-auto">
    <div style="scroll-margin-top: 100px" v-for="item in items" :key="item.title">
      <v-list density="default" class="pt-1 pb-1" v-if="item.visible" nav>
        <v-list-group v-if="item.visible && 'items' in item && item.items.length > 0" v-model="item.active" :key="item.title" no-action>
          <template v-slot:activator="{ props }">
            <v-list-item dense v-bind="props" :title="item.title">
              <template v-slot:prepend>
                <img :src="require('@/assets/images/sidebar/' + item.icon)" class="sidebar-icon mr-4 ml-2" />
              </template>
            </v-list-item>
          </template>

          <router-link v-for="subItem in item.items" :key="subItem.title" :to="'/' + subItem.path">
            <v-list-item :class="expandMenu ? 'subitem text-white' : 'subitem text-white hidden'" v-if="subItem.visible" ripple @click="close">
              <template v-slot:prepend></template>
              <v-list-item-title>{{ subItem.title }}</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-group>

        <router-link v-else-if="item.visible" :to="'/' + item.path">
          <v-list-item dense class="text-white pl-1" :key="item.title" link>
            <template v-slot:prepend>
              <img :src="require('@/assets/images/sidebar/' + item.icon)" class="sidebar-icon mr-4 ml-2" />
            </template>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center image-sidebar">
    <v-img transition="fade-transition" :src="require('@/assets/images/logo-full-yegoo-blanco.png')" style="max-width: 120px" />
  </div>
</v-card>

</template>

<script>
import Constant from "@/constants/sections";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import UserType from "@/constants/user_type";

export default {
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      isExternal: null,
      logisticItems: [
        { title: "Monitoreo", icon: "bx-desktop.svg", path: Constant.SECTION_MONITORING, visible: true, active: false },
        { title: "Cotizaciones", icon: "bx-package.svg", path: Constant.SECTION_QUOTINGS, visible: true, active: false },
        { title: "Ordenes", icon: "bx-package.svg", path: Constant.SECTION_ORDERS, visible: true, active: false },
        { title: "Facturación", icon: "bx-receipt.svg", path: Constant.SECTION_INVOICING, visible: true, active: false },
        {
          title: "Administrar",
          icon: "bx-cog.svg",
          active: false,
          visible: true,
          items: [
            { title: "Choferes", icon: "/", path: Constant.SECTION_DRIVER, visible: true },
            { title: "Vehículos", icon: "/", path: Constant.SECTION_VEHICLE, visible: true },
            { title: "Tarifas", icon: "/", path: Constant.SECTION_FEE, visible: true },
            { title: "Colaboradores", icon: "/", path: Constant.SECTION_COLLABORATORS, visible: true },
            { title: "Roles", icon: "/", path: Constant.SECTION_ROLES, visible: true },
          ],
        },
      ],
      sellerItems: [
        { title: "Monitoreo", icon: "bx-desktop.svg", path: Constant.SECTION_MONITORING, visible: true, active: false },
        {
          title: "Envios",
          icon: "bx-package.svg",
          active: false,
          visible: true,
          items: [
            { title: "Envios sin asignar", icon: "/", path: Constant.SECTION_SHIPPING + "/not-assigned", visible: true },
            { title: "Envios asignados", icon: "/", path: Constant.SECTION_SHIPPING + "/assigned", visible: true },
          ],
        },
        {
          title: "Ordenes",
          icon: "bx-package.svg",
          active: false,
          visible: true,
          path: Constant.SECTION_ORDERS

        },
        { title: "Facturación", icon: "bx-receipt.svg", path: Constant.SECTION_INVOICING, visible: true, active: false },
        {
          title: "Administrar",
          icon: "bx-cog.svg",
          active: false,
          visible: true,
          items: [
            { title: "Colaboradores", icon: "/", path: Constant.SECTION_COLLABORATORS, visible: true },
            { title: "Roles", icon: "/", path: Constant.SECTION_ROLES, visible: true },
            { title: "Mercado Libre", icon: "/", path: Constant.SECTION_MELI, visible: true },

          ],
        },
      ],
      items: [],
      right: null,
      expandMenu: this.$store.state.sidebar.open ? true : false,
      isMobile: false,
      user: "Usuario",
    };
  },
  mounted() {
    this.loadSideBar();
    this.loadUser();
  },
  watch: {
    "$store.state.sidebar.open": function () {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = false;
      }
    },
  },
  methods: {
    loadUser() {
      const userObject = JSON.parse(localStorage.getItem("app_object"));

      this.user = userObject?.name.split(" ")[0];
    },
    handleExpandMenu(value) {
      if (this.$store.state.sidebar.open) {
        this.expandMenu = true;
      } else {
        this.expandMenu = value;
      }
    },
    close() { },
    loadSideBar() {
      this.items = this.getItemsAvailable();
    },
    getItemsAvailable() {
      var items = [];
      let itemsAvailable;
      switch (localStorage.getItem("user_type")) {
        case UserType.SELLER:
        case UserType.EMPLOYEE_SELLER:
          itemsAvailable = JSON.parse(JSON.stringify(this.sellerItems));
          break;
        case UserType.LOGISTIC:
        case UserType.EMPLOYEE_LOGISTIC:
          itemsAvailable = JSON.parse(JSON.stringify(this.logisticItems));
          break;
        default:
          itemsAvailable = JSON.parse(JSON.stringify(items));
          break;
      }

      // itemsAvailable.forEach((item) => {
      //   if ("items" in item) {
      //     let any_visible = false;
      //     item.items.forEach((sub_item) => {
      //       //sub_item.visible = sub_item.path in this.roles && this.roles[sub_item.path].sidebar;
      //       sub_item.visible = true
      //       if (sub_item.visible) any_visible = true;
      //     });
      //     //item.visible = any_visible;

      //     item.visible = true
      //   } else {
      //     item.visible = true
      //     //item.visible = item.path in this.roles && this.roles[item.path].sidebar;
      //   }
      // });
      return itemsAvailable;
    },
  },
};
</script>
