<template>
    <div>

    </div>
</template>

<script>

import { meliAccountsService } from '@/libs/ws/Seller/meliAccountsService';

export default {
    name: "MeliCallback",
    components: {},
    mixins: [],
    data() {
        return {
            service: meliAccountsService,
            text: "",
            snackbar: false,
        }
    },
    watch: {

    },
    mounted() {
        this.authAccount();
    },
    methods: {
        authAccount() {

            const body = {
                code: this.$route.query.code
            }

            this.service
                .authAccount(body)
                .then((response) => {
                    if (response.status === true) {
                        this.toast.success(response.msg);

                    } else if (Object.prototype.hasOwnProperty.call(response, "message")) {
                        this.toast.error("El elemento se borro correctamente.");
                    } else {
                        this.toast.error(response.msg);
                    }



                    this.$router.push("/meli_accounts");
                })
                .catch((e) => e)
        }
    },
};
</script>
