<template>
  <div>
    <v-dialog :modelValue="open" max-width="500">
      <v-card>
        <v-card-title class="p-3 header-modal mb-4">
          <span class="headline text-white">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <p class="mt-3 mb-3 fs-16 text-center">
            {{ message }}
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="$emit('cancel')"> {{ nameButtonNo }} </v-btn>

          <v-btn color="green darken-1" text @click="$emit('accept')" :loading="loading"> {{ nameButtonYes }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    open: Boolean,
    cancel: Function,
    accept: Function,
    nameButtonNo: {
      default: "No",
      type: String
    },
    nameButtonYes: {
      default: "Si",
      type: String
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({}),
  methods: {},
};
</script>
