import { wsService } from "../wsService";
import UserType from "@/constants/user_type";
const api_url = process.env.VUE_APP_API_URL;

function login(email, password) {
  const param = {
    email: email,
    password: password,
  };
  return wsService.make_post_request("auth/login/website", param);
}

function forgotPassword(param) {
  return wsService.make_post_request("register/recover/password", param);
}

function newPassword(param) {
  return wsService.make_post_request("register/recover/new_password", param);
}

function register(data, user_type, isProviderSingup) {
  switch (user_type) {
    case UserType.SELLER:
      return registerSeller(data, isProviderSingup);
    case UserType.LOGISTIC:
      return registerLogistic(data, isProviderSingup);
    case UserType.DRIVER_INDEPENDENT:
      return registerIdriver(data, isProviderSingup);
  }
}

function decodeProviderToken(access_token) {
  const param = {
    access_token: access_token,
  };
  return wsService.make_post_request("register/decode_token/google", param);
}

function registerSeller(param, isProviderSingup) {
  if (isProviderSingup) {
    return wsService.make_post_request("register/seller/google/finish", param);
  } else {
    return wsService.make_post_request("register/seller/custom", param);
  }
}

function registerLogistic(param, isProviderSingup) {
  if (isProviderSingup) {
    return wsService.make_post_request("register/logistic/google/finish", param);
  } else {
    return wsService.make_post_request("register/logistic/custom", param);
  }
}

function registerIdriver(param, isProviderSingup) {
  if (isProviderSingup) {
    return wsService.make_post_request("register/idriver/google/finish", param);
  } else {
    return wsService.make_post_request("register/idriver/custom", param);
  }
}

function validateEmail(param) {
  return wsService.make_get_request("register/validate/" + param.user_id + "/" + param.hash);
}

function VerifySocialLogin(social, param) {
  switch (social) {
    case "google":
      return wsService.make_post_request("auth/verify/website/google", param);      
  }
}

function isAuthenticated() {
  return localStorage.getItem("token") != undefined && localStorage.getItem("expire_in") != undefined && Math.floor(Date.now() / 1000) < localStorage.getItem("expire_in");
}

function logOut() {
  localStorage.clear();
}

export const authService = {
  login,
  forgotPassword,
  newPassword,
  isAuthenticated,
  VerifySocialLogin,
  logOut,
  register,
  validateEmail,
  decodeProviderToken,
};
