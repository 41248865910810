<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="500px" transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <Button flat class="bg-button-register btn text-capitalize text-white me-1" :v-bind="props" @click="$emit('updateDialog', true)" rounded="xl" height="50" :label="'+ ' + button_name"></Button>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-label>Tipo*</v-label>
                <!-- <v-text-field v-model="item.vehicle_type" density="compact" persistent-hint variant="outlined" :disabled="disabled"></v-text-field> -->

                <v-combobox
                  density="compact"
                  :rules="[rules.requiredComboBox]"
                  persistent-hint
                  item-title="name"
                  item-value="id"
                  v-model="item.vehicle_type"
                  :items="typesVehicle"
                  variant="outlined"
                  autocomplete="off"
                  :disabled="disabled"
                ></v-combobox>
                <v-label>Patente*</v-label>
                <v-text-field v-model="item.patente" :disabled="disabled" density="compact" :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>

                <v-label>Marca*</v-label>
                <v-text-field v-model="item.vehicle_brand" :disabled="disabled" density="compact" :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>

                <v-label>Modelo*</v-label>
                <v-text-field v-model="item.modelo" density="compact" persistent-hint variant="outlined" :disabled="disabled" :rules="[rules.required]"></v-text-field>

                <v-label>Año*</v-label>
                <v-text-field v-model="item.ano" density="compact" persistent-hint variant="outlined" :disabled="disabled" :rules="[rules.required]"></v-text-field>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl" height="50" text @click="$emit('updateDialog', false)" label="Cerrar" color="error"></Button>

            <Button flat :loading="loading" text type="submit" class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50" :label="formBtn" v-if="!disabled"></Button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { vehiclesService } from "@/libs/ws/Logistic/vehiclesService";

export default {
  mixins: [ValidMixin],
  components: { Button },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      name: "",
      item: {
        id: 0,
        vehicle_type: "",
        patente: "",
        vehicle_brand: "",
        modelo: "",
        ano: "",
      },
      typesVehicle: [],
      defaultItem: null,
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.loadTypesVehicle();
  },
  methods: {
    loadTypesVehicle() {
      vehiclesService
        .getAllTypesVehicle()
        .then((response) => {
          this.typesVehicle = response.result;
        })
        .catch((e) => {
          console.log("service.load types vehicle error");
          console.log(e);
        });
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        var data = this.item;

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modificó correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creó correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
