import { wsService } from "../wsService";


const section = "postal_codes";

function getPostalCodes(param = {}) {
    return wsService.make_post_request(section + "/list", param);
  }
  

export const postalCodesService = {
    getPostalCodes,
};
