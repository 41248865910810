const SUPERUSER = "SU";
const LOGISTIC = "LO";
const SELLER = "SE";
const EMPLOYEE_SELLER = "ES";
const EMPLOYEE_LOGISTIC = "EL";
const DRIVER_LOGISTIC = "DL";
const DRIVER_INDEPENDENT = "DI";

export default {
  SUPERUSER: SUPERUSER,
  LOGISTIC: LOGISTIC,
  SELLER: SELLER,
  EMPLOYEE_SELLER: EMPLOYEE_SELLER,
  EMPLOYEE_LOGISTIC: EMPLOYEE_LOGISTIC,
  DRIVER_LOGISTIC: DRIVER_LOGISTIC,
  DRIVER_INDEPENDENT: DRIVER_INDEPENDENT,
};
