<template>
  <v-btn :active="disabled" :loading="loading" :type="type" :class="classes" :rounded="rounded" :height="height" @click="$emit('click')">
      <v-icon v-if="hasIcon" :size="iconSize">{{ icon }}</v-icon>
      {{ label }}
    </v-btn>
  </template>
  <script>
  export default {
    props: {
      classes: String,
      rounded: String,
      height: String,
      icon: String,
      iconSize: String,
      label: String,
      onClick: Function,
      loading: Boolean,
      type: String,
      disabled: Boolean
    },
    computed: {
      hasIcon() {
        return this.icon && this.icon.length > 0;
      },
    },
  };
  </script>
  