<template>
  <v-card class="custom-card" :class="{ 'disabled-card': !isConfigure }">

    <v-card-text>
      <v-row>
        <v-col cols="8">
          <v-label class="title-card-fee mb-3">{{ zoneGroup.zone_group.name }}</v-label>
          <div v-if="isConfigure">
            <div>
              <v-label class="text-card-fee mb-3">$  {{ zoneGroup.price }}</v-label>
            </div>
              <v-chip v-for="zone in zoneGroup.zones" :key="zone.id" class="chip-fee" size="small">
                {{ zone.name }}
              </v-chip>
          </div>
          <div v-else>
            <v-label class="text-card-fee-disabled">
              No esta configurada la zona.
            </v-label>
          </div>
        </v-col>

        <v-col class="d-flex justify-end" cols="4">
          <Button type="submit" class="bg-button-register btn text-capitalize text-white" rounded="xl" height="40"
            label="Configurar" @click="handleConfigure"></Button>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "CardFee",
  components: { Button },
  props: {
    cardTitle: String,
    inputLabel: String,
    inputDescription: String,
    updateDialog: Function,
    isConfigure: Boolean,
    editItem:Function,
    zoneGroup: Object,
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    handleConfigure() {
      this.$emit("editItem", this.zoneGroup);
    }
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "TARIFAS" }] });
  },
};
</script>
