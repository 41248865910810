<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <p class="text-component">
          Configura las tarifas a las zonas donde desees prestar servicio.
        </p>
        <div class="container-card-fee">
          <div class="content-card-fee">
            <div v-for="(zoneGroup) in list" :key="zoneGroup.id">
              <CardFee :zoneGroup="zoneGroup" :cardTitle="zoneGroup.zone_group.name" :inputLabel="'Escribe un monto'"
                :basePrice="zoneGroup.zone_group.base_price" @updateDialog="updateDialog" @editItem="editItem"
                :isConfigure="zoneGroup.price != null && zoneGroup.zones.length > 0"></CardFee>
            </div>
          </div>
        </div>
        <Modal :formBtn="formBtn" :dialog="dialog"
          :service="service" @updateDialog="updateDialog" @loadList="loadList(service)" :editItem="mainItem" :loading_submit="loading" />
      </div>
    </v-container>
  </div>
</template>

<script>
import CardFee from "@/components/CardFee";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import Modal from "./Modal.vue";
import { ratesService } from "@/libs/ws/Logistic/ratesService";


export default {
  name: "Fee",
  components: { CardFee, Modal },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  props: {
  },
  data() {
    return {
      service: ratesService,
      mainItem: {
        zone_groups: {
          id: 0,
          name: "",
          base_price: "",
          min_km: "",
          max_km: ""
        },
        zones: [],
        price: null,
      },
      zoneGroups: []
    };
  },
  watch: {

  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "TARIFAS" }] });
    this.loadList(this.service);
  },
  methods: {

  },
};
</script>
