<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">
          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Filters  @loadList="loadList" :assigned="true" :service="service"/>


        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">{{ item.creation_date }}</td>
            <td class="table-cell">{{ item.code }}</td>
            <td class="table-cell">#{{ item.seller_order.id }}</td>
            <td class="table-cell">{{ item.destination_address }}<br />{{ item.destination_location }} </td>
            <td class="table-cell">{{ item.bultos }}</td>
            <td class="table-cell">{{ item.kg }}</td>
            <td class="table-cell">{{ item.state.name }}</td>

          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages"
          @next="loadPage(service, { no_order: 0 })" @prev="loadPage(service, { no_order: 0 })"
          @first="loadPage(service, { no_order: 0 })"
          @update:modelValue="loadPage(service, { no_order: 0 })"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { shippingService } from "@/libs/ws/Seller/shippingService";
import Filters from "./Filters.vue";

export default {
  name: "Employees",
  components: { Table , Filters},
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: shippingService,
      loading: false,
      header: [
        { name: "Fecha", value: "creation_date", align: "left" },
        { name: "Codigo de seguimiento", value: "code", align: "left" },
        { name: "Nº Orden", value: "seller_order.code", align: "left" },
        { name: "Destino", value: "", align: "left" },
        { name: "Bultos", value: "bultos", align: "left" },
        { name: "Kg", value: "kg", align: "left" },
        { name: "Estado", value: "state.name", align: "left" },
      ],
      modal_title: "Envios",
      button_name: "Crear",
      params: {
        from_date: new Date().toISOString().slice(0, 10),
        to_date: new Date().toISOString().slice(0, 10),
        no_order: 0
      }
    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "ENVIOS ASIGNADOS" }] });

    this.loadList(this.service, this.params);
  },
  methods: {},
};
</script>
