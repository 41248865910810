import { wsService } from "../wsService";

const section = "shippings";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}

function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function pregeo(param = {}) {
  return wsService.make_post_request(section + "/pregeo", param);
}

function states() {
  return wsService.make_get_request(section + "/states");
}

export const shippingService = {
  section,
  filter,
  remove,
  create,
  update,
  pregeo,
  states
};
