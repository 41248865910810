import { wsService } from "../wsService";

const section = "accounts";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/get", param);
}

function update(param = {}) {
  return wsService.make_post_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function getAllTypesVehicle() {
  return wsService.make_get_request("vehicle_types");
}

function updateProfilePic(param) {
  return wsService.make_post_request(section + "/update-profile-pic", param)
}
export const profileService = {
  section,
  updateProfilePic,
  filter,
  remove,
  update,
  getAllTypesVehicle
};
