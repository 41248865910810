<template>
  <div>
    <Register v-if="step == 0" @setUserType="setUserType"></Register>
    <Welcome v-if="step == 1" :tipo="tipo" @nextStep="nextStep" @back="back" @setUser="setUser"></Welcome>
    <RegisterSeller v-if="step == 2 && tipo == UserType.SELLER" @nextStep="nextStep" @back="back" :isProviderSingup="isProviderSingup()" :user="user" @setUser="setUser"> </RegisterSeller>
    <RegisterLogistic v-if="step == 2 && tipo == UserType.LOGISTIC" @nextStep="nextStep" @back="back" :isProviderSingup="isProviderSingup()" :user="user" @setUser="setUser"> </RegisterLogistic>
    <RegisterDriver v-if="step == 2 && tipo == UserType.DRIVER_INDEPENDENT" @nextStep="nextStep" @back="back" :isProviderSingup="isProviderSingup()" :user="user" @setUser="setUser"></RegisterDriver>
    <FinishRegister v-if="step == 3" :isProviderSingup="isProviderSingup()" :user="user"></FinishRegister>
  </div>
</template>

<script>
import Welcome from "./Welcome.vue";
import Register from "./Register.vue";
import RegisterSeller from "./RegisterSeller.vue";
import RegisterLogistic from "./RegisterLogistic.vue";
import RegisterDriver from "./RegisterDriver.vue";
import FinishRegister from "./FinishRegister.vue";
import UserType from "@/constants/user_type";
export default {
  name: "ContainerRegister",
  components: {
    Welcome,
    Register,
    RegisterSeller,
    RegisterLogistic,
    RegisterDriver,
    FinishRegister,
  },
  data: () => ({
    UserType: UserType,
    step: 0,
    tipo: null,
    user: {
      name: null,
      email: null,
      access_token: null,
    },
    userDefault: null,
  }),
  mounted() {
    this.userDefault = JSON.parse(JSON.stringify(this.user));
  },
  watch: {
    step() {
      if (this.step == 0) {
        this.user = this.userDefault;
      }
    },
  },
  methods: {
    nextStep() {
      this.step = this.step + 1;
    },
    setUserType(tipo) {
      this.tipo = tipo;
      this.nextStep();
    },
    setUser(user) {
      this.user = user;
    },
    back(count) {
      this.step = this.step - count;
    },
    isProviderSingup() {
      return this.user.access_token != null;
    },
  },
};
</script>
