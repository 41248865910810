import { wsService } from "../wsService";

const section = "orders";

function filter(param = { enable: true }) {
  return wsService.make_post_request(section + "/list", param);
}


function getByCode(id) {  
  return wsService.make_get_request(section + "/get_by_code?code=" + id);
}


function create(param = {}) {
  return wsService.make_post_request(section + "/create", param);
}

function add(param = {}) {
  return wsService.make_post_request(section + "/add_shippings", param);
}

function update(param = {}) {
  return wsService.make_put_request(section + "/update", param);
}

function remove(param = {}) {
  return wsService.make_delete_request(section + "/delete", param);
}

function removeShipping(param = {}) {
  return wsService.make_post_request(section + "/remove_shippings", param);
}

function quotation(param = {}) {
  return wsService.make_post_request(section + "/quotation", param);
}

function quotationDetail(param = {}) {
  return wsService.make_post_request(section + "/quotation-detail", param);
}

function quotationSelected(param = {}) {
  return wsService.make_post_request(section + "/quotation-selected", param);
}

function states() {
  return wsService.make_get_request(section + "/states");
}

function createPreference(param) {
  return wsService.make_post_request("mercadopago/create-preference", param);
}

export const ordersService = {
  section,
  filter,
  getByCode,
  remove,
  create,
  update,
  add,
  removeShipping,
  quotation,
  quotationDetail,
  quotationSelected,
  createPreference,
  states
};
