<template>
  <div class="register-welcome">
    <div class="container-logo-image">
      <a href="/">
        <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="150" class="" />
      </a>
    </div>

    <v-row align="center" justify="center" class="h-100">
      <v-col md="12" class="pa-0">
        <div class="justify-content-center text-white text-center">
          <h2 v-if="isProviderSingup">Te registraste correctamente</h2>
          <h2 v-else>Finalizá la registración</h2>
        </div>
        <div class="card-welcome card-finish rounded-0 h-100 bg m-auto mt-5">
          <v-card class="custom-card-welcome text-center">
            <div v-if="!isProviderSingup">
              <p>{{ user.name }} te enviamos un correo para validar tu email a:</p>
              <p>
                <b>{{ user.email }}</b>
              </p>
              <v-divider></v-divider>
            </div>

            <Button class="bg-button-register w-100 btn text-white" rounded="xl" height="50" @click="redirectLogin" label="Ir al Login"></Button>              
          </v-card>
          <div class="justify-content-center w-100 d-flex">
            <a href="/">
              <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="100" class="pt-10 mt-10" v-if="$vuetify.display.xs" />
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  name: "FinishRegister",

  props: {
    user: Object,
    isProviderSingup: Boolean,
  },

  components: {Button},

  data: () => ({}),

  methods: {
    redirectLogin() {
      this.$router.push("/login");
    },
  },
  mounted: () => {},
};
</script>
