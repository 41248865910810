<template>
    <div class="container-list">
        <v-container fluid>
            <div>
                <v-row class="mb-3">
                    <v-col md="6">
                        <Button flat class="bg-button-register btn text-capitalize text-white me-1" :v-bind="props"
                            @click="redirectToMercadoLibre" rounded="xl" height="50"
                            :label="'+ ' + button_name"></Button>

                    </v-col>
                    <v-col md="6">
                        <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
                            label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
                            @input="filterSearch"></v-text-field>
                    </v-col>

                </v-row>

                <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
                    :sortDesc="sortDesc">
                    <template v-slot:rows="{ item }">
                        <td class="table-cell">{{ item.nickname_ml }}</td>
                        <td class="table-cell">{{ item.creation_date.date }}</td>

                        <td class="table-cell text-center">

                            <img @click="deleteItem(item)" :src="require('@/assets/images/bx-trash.svg')"
                                class="ml-2 pointer" />
                        </td>
                    </template>
                </Table>


                <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
                    @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

                <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages"
                    @next="loadPage(service)" @prev="loadPage(service)" @first="loadPage(service)"
                    @update:modelValue="loadPage(service)"></v-pagination>
            </div>
        </v-container>
    </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Button from "@/components/Button.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { meliAccountsService } from "@/libs/ws/Seller/meliAccountsService";

export default {
    name: "MeliAccounts",
    components: { Button, Table },
    mixins: [GenericMixin, RolMixin],
    RolMixin,
    data() {
        return {
            service: meliAccountsService,
            header: [{ name: "Cuenta Vinculada", value: "nickname_ml", align: "left" }, { name: "Fecha creación", value: "creation_date.date", align: "left" }, { name: "Acción", align: "center" }],
            button_name: "Agregar cuenta",
            client_id: "",
            loading: false,
            snackbar: false,
            textSnackbar: ""
        };
    },
    watch: {
        searchQuery() {
            this.filterSearch();
        },
    },
    mounted() {
        this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "MERCADO LIBRE" }] });

        this.loadList(this.service);
        this.loadClientID();
    },
    methods: {
        loadClientID() {
            meliAccountsService
                .getClientId()
                .then((response) => {
                    this.client_id = response.result.client_id

                })
                .catch((e) => e)
        },
        redirectToMercadoLibre() {
            window.location.href = `http://auth.mercadolibre.com.ar/authorization?response_type=code&client_id=${this.client_id}`;
        },


    },
};
</script>
