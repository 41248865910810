<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="1000px"
      transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <Button flat class="bg-button-register btn text-capitalize text-white me-1" :v-bind="props"
          @click="$emit('updateDialog', true)" rounded="xl" height="50" :label="'+ ' + button_name"></Button>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title class="p-3 header-modal mb-3">
            <span class="headline text-white">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text class="pb-0">
            <!-- START FORM DIALOG -->
            <v-row class="align-center justify-center mb-4">
              <v-col md="11" class="elevation-0 pt-4 pb-4 mt-4" style="background: #f5f5f5">
                <v-stepper v-model="step" :items="steps" hide-actions>
                  <template v-slot:[`item.${1}`]>
                    <v-row class="align-start justify-center" transition="fade-transition">
                      <v-col md="6" class="pr-0 pl-0">
                        <v-form ref="form_step1">
                          <div class="px-2 py-1 mb-3 bg-subtitle-section">
                            <p class="text-subtitle-1 mb-0"><b>Rol</b></p>
                          </div>
                          <p>
                            Los roles le permiten acceder o no a ciertas funcionalidades dentro del sistema. Una vez
                            creado el
                            rol este podrá asignarse a uno o varios empleados. Todos los empleados con el
                            mismo rol tendran los mismos permisos.
                          </p>
                          <v-text-field variant="outlined" density="compact" label="Nombre*" v-model="item.name"
                            :disabled="disabled" :rules="[rules.required]" class="mb-1"></v-text-field>
                          <v-text-field variant="outlined" density="compact"
                            hint="Complete una descripcion que permita identificar las tareas del Rol"
                            label="Descripcion*" v-model="item.description" :disabled="disabled"
                            :rules="[rules.required]"></v-text-field>
                          <v-checkbox density="compact" label="Habilitado" :disabled="disabled"
                            v-model="item.enable"></v-checkbox>

                        </v-form>
                      </v-col>
                    </v-row>

                  </template>
                  <template v-slot:[`item.${2}`]>

                    <div v-if="step == 2" transition="fade-transition">
                      <v-row class="align-center justify-center">
                        <v-col md="11">
                          <v-data-table-virtual :headers="headers_modules" :disabled="disabled" density="compact"
                            :items="item.modules.length != 0 ? item.modules : modules" :loading="loading_table"
                            loading-text="Cargando... Por favor espere" :hide-default-footer="true" class="mb-4">
                            <template v-slot:headers="{ headers }">
                              <th v-for="(item, index) in headers[0]" :key="index" class="py-3 px-1 text-white bg-grey">
                                <p style="white-space: nowrap" class="mb-0">
                                  {{ item.title }}
                                  <v-tooltip content-class="bg-dark" top v-if="item.tooltip != null">
                                    <template v-slot:activator="{ props }">
                                      <font-awesome-icon v-bind="props" icon="info-circle" class="cursor" />
                                    </template>
                                    <span v-html="item.tooltip"></span>
                                  </v-tooltip>
                                </p>
                              </th>
                            </template>

                            <template v-slot:item="{ item }">
                              <tr>

                                <td style="max-width: 350px">
                                  <b>
                                    {{ item.name }}
                                  </b>
                                  <br />
                                  {{ item.module.description }}
                                </td>
                                <td>
                                  <v-checkbox class="check-rol" density="compact" v-model="item.list" :disabled="disabled"
                                    @change="updateSidebar(item)"></v-checkbox>
                                </td>
                                <td>
                                  <v-checkbox class="check-rol" density="compact" v-model="item.create"
                                    :disabled="disabled" @change="updateSidebar(item)"></v-checkbox>
                                </td>
                                <td>
                                  <v-checkbox class="check-rol" density="compact" v-model="item.update"
                                    :disabled="disabled" @change="updateSidebar(item)"></v-checkbox>
                                </td>
                                <td>
                                  <v-checkbox class="check-rol" density="compact" v-model="item.delete"
                                    :disabled="disabled" @change="updateSidebar(item)"></v-checkbox>
                                </td>
                                <!-- <td>
                                  <v-checkbox density="compact" v-model="item.sidebar" :disabled="disabled"></v-checkbox>
                                </td> -->
                              </tr>
                            </template>
                          </v-data-table-virtual>
                        </v-col>
                      </v-row>
                    </div>
                  </template>

                  <v-stepper-actions :disabled="false" v-if="step === 1" prev-text="Cerrar" next-text="Siguiente"
                    @click:next="next()" @click:prev="closeModal()">

                  </v-stepper-actions>

                  <v-stepper-actions :disabled="false" v-if="step === 2" prev-text="Atras" next-text="Crear"
                    @click:next="createItem()" @click:prev="prev()"></v-stepper-actions>

                </v-stepper>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl" height="50" text
              @click="closeModal()" label="Cerrar" color="error"></Button>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { rolesService } from "@/libs/ws/Public/rolesService";

export default {
  mixins: [ValidMixin],
  components: { Button },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    page_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
  },
  data() {
    return {
      show: false,
      disabled: false,
      step: 1,
      steps: [
        {
          title: "Rol",
          content: "First-content",
          value: 1
        },
        {
          title: "Modulos",
          content: "Second-content",
          value: 2
        },
      ],
      loading: false,
      loading_table: false,
      name: "",
      item: {
        id: 0,
        name: "",
        description: "",
        modules: [],
        enable: true,
      },
      defaultItem: null,
      modules: [],
      headers_modules: [
        { title: "Modulo", key: "name" },
        { title: "Listar", key: "list", tooltip: "Permite acceder a los listados del modulo. Ej: Listados Desplegables y Tablas" },
        { title: "Crear", key: "create", tooltip: "Permite crear elementos en el modulo" },
        { title: "Editar", key: "update", tooltip: "Permite editar elementos en el modulo" },
        { title: "Eliminar", key: "delete", tooltip: "Permite borrar elementos en el modulo" },

      ],
    };
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.loadModules();
  },
  methods: {
    updateSidebar(moduleItem) {
      const hasPermission = moduleItem.list || moduleItem.create || moduleItem.update || moduleItem.delete;

      moduleItem.sidebar = hasPermission;
    },
    prev() {
      this.step--;
    },
    async next() {
      const { valid } = await this.$refs.form_step1.validate();

      if (valid) {
        this.step++;
      }
    },
    loadModules() {
      this.loading_table = true;
      rolesService
        .listModules()
        .then((response) => {
          this.modules = response.result.list;
          this.loading_table = false;
        })
        .catch((e) => {
          this.loading_table = false;
        });
    },
    closeModal() {
      this.$emit('updateDialog', false);
      this.item.modules = [];
      this.step = 1;
    },
    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        if (this.item.id <= 0) {
          this.item.modules = this.modules;
        }
        var data = {
          id: this.item.id,
          name: this.item.name,
          description: this.item.description,
          enable: this.item.enable ? 1 : 0,
          web_access: this.item.web_access ? 1 : 0,
          app_access: this.item.app_access ? 1 : 0,
          modules: this.item.modules,
        };

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El rol " + this.item.name + " se modificó correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
                this.item.modules = [];
                this.step = 1;
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El rol " + this.item.name + " se creó correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
                this.item.modules = [];
                this.step = 1;
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
