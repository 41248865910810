<template>
  <v-app class="scrollbar-hidden" v-if="!viewRequiresAuth()">
    <router-view></router-view>
  </v-app>

  <v-app class="scrollbar-hidden" v-else>
    <v-navigation-drawer floating :width="240" dark app class="rounded-0 sidebar">
      <Sidebar></Sidebar>
    </v-navigation-drawer>
    

    <v-app-bar class="elevation-0" dense app>
      <Header></Header>
    </v-app-bar>
    
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "App",
  components: { Header, Sidebar },
  data: () => ({}),
  created() {
    document.title = "Yegoo - Tu Producto, Donde Sea";
  },
  mounted() {
    window.document.title = "Yegoo - Tu Producto, Donde Sea";
  },
  methods: {
    viewRequiresAuth() {
      return this.$route.matched.some((record) => record.meta.requiresAuth);
    },
  },
};
</script>
