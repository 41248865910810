<template>
  <div class="register-step2">
    <div class="container-logo-image justify-center">
      <a href="/">
        <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="150" />
      </a>
    </div>

    <v-row align="center" justify="center" class="h-100">
      <v-col md="12" class="pa-0">
        <div class="card-step2 rounded-0 h-100 bg m-auto">
          <div class="justify-content-center text-white text-center">
            <h2>Registrate</h2>
          </div>

          <v-breadcrumbs class="mb-0" color="#fff" :items="breadcrumbs">
            <template v-slot:divider>
              <v-icon color="#fff" icon="mdi-chevron-right"></v-icon>
            </template>
            <template v-slot:title="{ item }">
              <span role="button" @click="$emit('back', item.count)">{{ item.title }}</span>
            </template>
          </v-breadcrumbs>

          <v-card class="custom-card-step2">
            <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="submit">
              <v-row>
                <v-col cols="12" md="6">
                  <v-label>Nombre y apellido</v-label>
                  <v-text-field :disabled="isProviderSingup" density="compact" :rules="[rules.required]" persistent-hint variant="outlined" v-model="formData.name"></v-text-field>
                  <v-label>Email</v-label>
                  <v-text-field :disabled="isProviderSingup" density="compact" :rules="[rules.required, rules.email]" persistent-hint variant="outlined" v-model="formData.email"></v-text-field>

                  <div v-if="!isProviderSingup">
                    <v-label>Contraseña</v-label>
                    <v-text-field
                      density="compact"
                      persistent-hint
                      variant="outlined"
                      v-model="formData.password"
                      outlined
                      :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append-inner="showPassword = !showPassword"
                      :type="showPassword ? 'text' : 'password'"
                    ></v-text-field>
                  </div>

                  <div v-if="!isProviderSingup">
                    <v-label>Confirmar Contraseña</v-label>
                    <v-text-field
                      :rules="[confirmPasswordValidation(formData.password)]"
                      density="compact"
                      persistent-hint
                      variant="outlined"
                      v-model="formData.password_confirmation"
                      outlined
                      :type="showPasswordConfirmation ? 'text' : 'password'"
                      :append-inner-icon="showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append-inner="showPasswordConfirmation = !showPasswordConfirmation"
                    ></v-text-field>
                  </div>

                  <v-label>Teléfono</v-label>
                  <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined" v-model="formData.phone"></v-text-field>

                  <div v-if="isProviderSingup">
                    <v-label>CUIT</v-label>
                    <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined" v-model="formData.cuit"></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <div v-if="!isProviderSingup">
                    <v-label>CUIT</v-label>
                    <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined" v-model="formData.cuit"></v-text-field>
                  </div>

                  <v-label>Razón Social</v-label>
                  <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined" v-model="formData.business_name" required></v-text-field>
                  <v-label>Dirección</v-label>
                  <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined" v-model="formData.address"></v-text-field>
                  <v-label>Ciudad</v-label>
                  <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined" v-model="formData.city"></v-text-field>
                  <v-label>Provincia</v-label>
                  <v-combobox
                    density="compact"
                    :rules="[rules.requiredComboBox]"
                    persistent-hint
                    item-title="name"
                    item-value="id"
                    v-model="formData.provincia"
                    :items="provincias"
                    variant="outlined"
                    autocomplete="off"
                  ></v-combobox>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="12" md="8" sm="12" class="pl-2">
                  <v-checkbox density="compact" :rules="[rules.requiredTerms]" v-model="formData.terms_and_condition" label="Acepto términos y condiciones | Políticas de privacidad" />
                </v-col>

                <v-col cols="12" md="4" sm="12" class="d-flex justify-content-end container-button">
                  <Button :loading="loading" type="submit" class="bg-button-register btn text-capitalize text-white" rounded="xl" height="50" label="Registrarme"></Button>              
                </v-col>
              </v-row>
            </v-form>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { authService } from "@/libs/ws/Public/authService";
import { provincesService } from "@/libs/ws/Public/provincesService";
import ValidMixin from "@/mixins/ValidMixin.vue";
import UserType from "@/constants/user_type";
import Button from "@/components/Button.vue";

export default {
  name: "RegisterSeller",
  props: {
    nextStep: Function,
    back: Function,
    setUser: Function,
    isProviderSingup: Boolean,
    user: { type: Object, default: null },
  },
  mixins: [ValidMixin],
  mounted() {
    if (this.user != null && this.user.name != null) {
      this.formData.name = this.user.name;
      this.formData.email = this.user.email;
      this.formData.access_token = this.user.access_token;
    }
    this.loadProvincias()

  },
  components: {Button},
  data() {
    return {
      loading: false,
      formData: {
        email: "",
        password: "",
        password_confirmation: "",
        name: "",
        phone: "",
        cuit: "",
        business_name: "",
        address: "",
        city: "",
        provincia: "",
        terms_and_condition: false,
        "g-recaptcha-response": null,
        access_token: null,
      },
      showPassword: false,
      showPasswordConfirmation: false,
      provincias: [],
      breadcrumbs: [
        {
          title: "Soy vendedor",
          disabled: false,
          count: 2,
        },
        {
          title: "Registrate",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    async submit() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      this.formData["g-recaptcha-response"] = await this.$recaptcha("login");

      this.createSeller();
    },
    confirmPasswordValidation(password) {
      return (value) => value === password || "Las contraseñas no coinciden.";
    },
    loadProvincias() {
      provincesService
        .getAllProvince()
        .then((response) => {
          this.provincias = response.result.list;
        })
        .catch((e) => {
          console.log("service.load register seller error");
          console.log(e);
        });
    },
    createSeller() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;
        authService
          .register(this.formData, UserType.SELLER, this.isProviderSingup)
          .then((response) => {
            if (response.status) {
              var user = {
                name: this.formData.name,
                email: this.formData.email,
                access_token: this.formData.access_token,
              };
              this.$emit("setUser", user);
              this.$emit("nextStep");
            } else {
              if (Object.prototype.hasOwnProperty.call(response.result, "email") && response.result.email[0] == "validation.unique") {
                this.toast.error("El email ya se encuentra registrado");
              } else {
                this.toast.error("Ocurrio un error al crear usuario");
              }
            }
          })
          .catch((e) => {
            console.log(e);
            this.toast.error("Ocurrio un error al crear usuario");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
