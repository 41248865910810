<template>
  <div class="container-list">
    <v-container fluid>
      <div>
        <v-row class="mb-3">
          <v-col md="6">
            <Modal button_name="Agregar Rol" :modal_title="modal_title" :page_title="page_title" :formBtn="formBtn"
              :dialog="dialog" :service="service" @updateDialog="updateDialog" @loadList="loadList(service)"
              :editItem="mainItem" />
          </v-col>
          <v-col md="6">
            <v-text-field class="input-search mb-0" :loading="loading" density="compact" variant="outlined"
              label="Busqueda" prepend-inner-icon="mdi-magnify" v-model="searchQuery"
              @input="filterSearch"></v-text-field>
          </v-col>
        </v-row>

        <Table :list="list" :header="header" :loading="loading" :toggleSort="toggleSort" :sortBy="sortBy"
          :sortDesc="sortDesc">
          <template v-slot:rows="{ item }">
            <td class="table-cell">{{ item.name }}</td>
            <td class="table-cell">{{ item.description }}</td>
            <td class="table-cell text-center">
              <img @click="editItem(item)" :src="require('@/assets/images/bx-pencil.svg')" class="mr-2 pointer" />
              <img @click="deleteItem(item)" :src="require('@/assets/images/bx-trash.svg')" class="ml-2 pointer" />
            </td>
          </template>
        </Table>

        <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete"
          @deleteItemConfirm="deleteItemConfirm" :loading="loadingSubmit" />

        <v-pagination v-if="cant_pages > 1" v-model="current_page" :length="cant_pages" @next="loadPage(service)"
          @prev="loadPage(service)" @first="loadPage(service)" @update:modelValue="loadPage(service)"></v-pagination>
      </div>
    </v-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import Modal from "./Modal.vue";
import GenericMixin from "@/mixins/GenericMixin.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { rolesService } from "@/libs/ws/Public/rolesService";

export default {
  name: "Roles",
  components: { Table, Modal },
  mixins: [GenericMixin, RolMixin],
  RolMixin,
  data() {
    return {
      service: rolesService,
      loading: false,
      header: [{ name: "Nombre", value: "name", align: "left" }, { name: "Descripción", value: "description", align: "left" }, { name: "Acción", align: "center" }],
      modal_title: "Roles",
      button_name: "Crear",
    };
  },
  watch: {
    searchQuery() {
      this.filterSearch();
    },
  },
  mounted() {
    this.$store.commit("setPageTitle", { pageTitle: [{ icon: '', page: "ROLES" }] });

    this.loadList(this.service);
  },
  methods: {},
};
</script>
