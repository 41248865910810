<template>
  <div class="register-welcome">
    <div class="container-logo-image">
      <a href="/">
        <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="150" class="" />
      </a>
    </div>

    <v-row align="center" justify="center" class="h-100">
      <v-col md="12" class="pa-0">
        <div class="title-container justify-content-center text-center">
          <h2>Recupero de contraseña</h2>
        </div>
        <div class="card-welcome card-finish rounded-0 m-auto mt-5">
          <v-card class="custom-card-welcome text-left">
            <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="submit">
              <p class="mx-0 my-0 mb-2">Introduce tu email para recuperar tu contraseña</p>
              <v-text-field :rules="[rules.required, rules.email]" persistent-hint variant="outlined" v-model="formData.email" placeholder="Email"></v-text-field>
              <v-divider></v-divider>

              <Button :loading="loading" type="submit" class="bg-button-register w-100 btn text-capitalize text-white mt-3" rounded="xl" height="50" label="Enviar Email"></Button>              

            </v-form>
          </v-card>
          <div class="justify-content-center w-100 d-flex">
            <a href="/">
              <v-img :src="require('@/assets/images/logo-full-yegoo-blanco.png')" contain max-width="100" class="pt-10 mt-10" v-if="$vuetify.display.xs" />
            </a>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import { authService } from "@/libs/ws/Public/authService";
import Button from "@/components/Button.vue";

export default {
  name: "ForgotPassword",

  props: {
    hasStepPassword: Function,
  },

  mixins: [ValidMixin],

  components: {Button},

  data: () => ({
    loading: false,
    formData: {
      email: "",
      "g-recaptcha-response": null,
    },
  }),

  methods: {
    async submit() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      this.formData["g-recaptcha-response"] = await this.$recaptcha("login");

      this.forgotPassword();
    },
    forgotPassword() {
      if (this.loading) return;

      var param = {
        email: this.formData.email,
      };
      if (this.valid) {
        this.loading = false;
        authService
          .forgotPassword(param)
          .then((response) => {
            if (response.status) {
              this.$emit("hasStepPassword", this.formData.email);
            } else {
              this.toast.error("Ocurrio un error al recuperar tu contraseña, revisa el email que ingresaste.");
            }
          })
          .catch((e) => {
            this.toast.error("Ocurrio un error al enviar el email");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted: () => {},
};
</script>
