<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" @click:outside="$emit('updateDialog', false)" max-width="1000px"
      transition="dialog-bottom-transition">
      <template v-slot:activator="{ props }">
        <Button flat class="bg-button-register btn text-capitalize text-white me-1" :v-bind="props"
          @click="$emit('updateDialog', true)" rounded="xl" height="50" :label="'+ ' + button_name"></Button>
      </template>

      <v-card>
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="createItem">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">{{ modal_title }}</span>
          </v-card-title>


          <v-card-text class="pb-0" style="min-height: 750px;">
            <!-- START FORM DIALOG -->
            <v-row class="align-center justify-center mb-4">
              <v-col md="11" class="elevation-0 pt-4 pb-4" style="background: #f5f5f5">
                <v-stepper v-model="step" :items="steps" hide-actions>
                  <template v-slot:[`item.${1}`]>
                    <v-form ref="form_step1">
                      <v-row>
                        <v-col cols="6">
                          <v-label>Remitente</v-label>
                          <v-text-field v-model="item.origin_name" :disabled="disabled" density="compact"
                            :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>
                          <v-label>Provincia</v-label>
                          <v-combobox v-model="item.origin_provincia" density="compact" variant="outlined" name="role"
                            item-title="name" item-value="id" :items="provincias" autocomplete="off"
                            :disabled="disabled" :rules="[rules.requiredComboBox]" clearable
                            @update:modelValue="loadOriginPostalCodes(item.origin_provincia.id)"></v-combobox>
                          <v-label>Código postal</v-label>
                          <v-combobox v-model="item.origin_postal_code" density="compact" variant="outlined" name="role"
                            item-title="code" item-value="id" :items="originPostalCodes" autocomplete="off"
                            :disabled="disabled" :rules="[rules.requiredComboBox]" clearable
                            :loading="loadingOriginPostalCode"></v-combobox>

                          <v-label>Localidad</v-label>
                          <v-text-field v-model="item.origin_location" :disabled="disabled" density="compact"
                            :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>
                          <v-label>Dirección</v-label>
                          <v-text-field v-model="item.origin_address" :disabled="disabled" density="compact"
                            :rules="[rules.required]" persistent-hint variant="outlined"
                            :error-messages="originAddressError ? [originAddressError] : []" @change="loadPregeoOrigin"
                            :loading="loadingOriginAddress">
                          </v-text-field>
                          <v-label>Referencia/Piso/Departamento</v-label>
                          <v-text-field v-model="item.origin_reference" :disabled="disabled" density="compact"
                            persistent-hint variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-label>Teléfono</v-label>
                          <v-text-field v-model="item.origin_telephone" :disabled="disabled" density="compact"
                            persistent-hint variant="outlined"></v-text-field>

                          <v-label>Email</v-label>
                          <v-text-field v-model="item.origin_email" :disabled="disabled" density="compact"
                            :rules="item.origin_email ? [rules.email] : []" persistent-hint
                            variant="outlined"></v-text-field>
                          <v-row>
                            <v-col cols="6">
                              <v-label>Latitud</v-label>
                              <v-text-field v-model="item.origin_lat" :disabled="disabled" density="compact"
                                :rules="[rules.required, rules.float, rules.latitud]" persistent-hint
                                variant="outlined"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-label>Longitud</v-label>
                              <v-text-field v-model="item.origin_lng" :disabled="disabled" density="compact"
                                :rules="[rules.required, rules.float, rules.longitud]" persistent-hint
                                variant="outlined"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pt-0">
                              <Maps :lat="item.origin_lat" :lng="item.origin_lng"></Maps>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </template>
                  <template v-slot:[`item.${2}`]>
                    <v-form ref="form_step2">
                      <v-row>
                        <v-col cols="6">
                          <v-label>Remitente</v-label>
                          <v-text-field v-model="item.destination_receiver_name" :disabled="disabled" density="compact"
                            :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>
                          <v-label>Provincia</v-label>
                          <v-combobox v-model="item.destination_provincia" density="compact" variant="outlined"
                            name="role" item-title="name" item-value="id" :items="provincias" autocomplete="off"
                            :disabled="disabled" :rules="[rules.requiredComboBox]" clearable
                            @update:modelValue="loadDestinationPostalCodes(item.destination_provincia.id)"></v-combobox>
                          <v-label>Código postal</v-label>
                          <v-combobox v-model="item.destination_postal_code" density="compact" variant="outlined"
                            name="role" item-title="code" item-value="id" :items="destinationPostalCodes"
                            autocomplete="off" :disabled="disabled" :rules="[rules.requiredComboBox]" clearable
                            :loading="loadingDestinationPostalCode"></v-combobox>
                          <v-label>Localidad</v-label>
                          <v-text-field v-model="item.destination_location" :disabled="disabled" density="compact"
                            :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>
                          <v-label>Dirección</v-label>
                          <v-text-field v-model="item.destination_address" :disabled="disabled" density="compact"
                            :rules="[rules.required]" persistent-hint variant="outlined"
                            :error-messages="destinationAddressError ? [destinationAddressError] : []"
                            @change="loadPregeoDestination" :loading="loadingDestinationAddress"></v-text-field>
                          <v-label>Referencia/Piso/Departamento</v-label>
                          <v-text-field v-model="item.destination_reference" :disabled="disabled" density="compact"
                            persistent-hint variant="outlined"></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-label>Teléfono</v-label>
                          <v-text-field v-model="item.destination_telephone" :disabled="disabled" density="compact"
                            persistent-hint variant="outlined"></v-text-field>
                          <v-label>Email</v-label>
                          <v-text-field v-model="item.destination_email" :disabled="disabled" density="compact"
                            :rules="item.destination_email ? [rules.email] : []" persistent-hint
                            variant="outlined"></v-text-field>
                          <v-row>
                            <v-col cols="6">
                              <v-label>Latitud</v-label>
                              <v-text-field v-model="item.destination_lat" :disabled="disabled" density="compact"
                                :rules="[rules.required, rules.float, rules.latitud]" persistent-hint
                                variant="outlined"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-label>Longitud</v-label>
                              <v-text-field v-model="item.destination_lng" :disabled="disabled" density="compact"
                                :rules="[rules.required, rules.float, rules.longitud]" persistent-hint
                                variant="outlined"></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pt-0">
                              <Maps :lat="item.destination_lat" :lng="item.destination_lng"></Maps>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </template>
                  <template v-slot:[`item.${3}`]>
                    <v-row style="min-height: 522px;">
                      <v-col cols="6">
                        <v-label>Bultos</v-label>
                        <v-text-field v-model="item.bultos" :disabled="disabled" density="compact"
                          :rules="[rules.required]" persistent-hint variant="outlined"></v-text-field>
                        <v-label>Valor declarado</v-label>
                        <v-text-field v-model="item.declared_value" :disabled="disabled" density="compact"
                          persistent-hint variant="outlined"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-label>Kilógramos</v-label>
                        <v-text-field v-model="item.kg" :disabled="disabled" density="compact" :rules="[rules.required]"
                          persistent-hint variant="outlined"></v-text-field>
                        <v-label>Identificador Externo</v-label>
                        <v-text-field v-model="item.external_reference" :disabled="disabled" density="compact"
                          persistent-hint variant="outlined"></v-text-field>
                      </v-col>
                    </v-row>
                  </template>

                  <v-card-actions>
                    <Button flat class="btn text-capitalize text-white me-1 btn-modal" type="button" rounded="xl"
                      height="50" text @click="closeModal()" label="Cerrar" color="error"></Button>

                    <div class="w-100">
                      <v-stepper-actions v-if="step === 1" prev-text="Atras" next-text="Siguiente"
                        @click:next="nextForm1()" @click:prev="closeModal()" class="pb-0">
                      </v-stepper-actions>
                      <v-stepper-actions :disabled="false" v-if="step === 2" prev-text="Atras" next-text="Siguiente"
                        @click:next="nextForm2()" @click:prev="prev()" class="pb-0"></v-stepper-actions>
                      
                      <div class="d-flex justify-content-between w-100 ps-2 pe-4">
                        <Button v-if="step === 3" flat type="button" class="btn text-capitalize me-1 btn-modal" rounded="xl"
                          height="50" text @click="prev" label="Atras" color="purple"></Button>
  
                        <Button flat :loading="loading" text type="submit"
                          class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
                          :label="formBtn" v-if="step===3"></Button>

                      </div>

                    </div>

                  </v-card-actions>

                </v-stepper>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import Maps from "@/components/Maps.vue";
import { provincesService } from "@/libs/ws/Public/provincesService";
import { postalCodesService } from "@/libs/ws/Public/postalCodesService";
import { profileService } from "@/libs/ws/Public/profileService";

export default {
  mixins: [ValidMixin],
  components: { Button, Maps },
  props: {
    service: Object,
    button_name: String,
    modal_title: String,
    formBtn: String,
    dialog: Boolean,
    updateDialog: Function,
    loadList: Function,
    editItem: Object,
    loading_submit: Boolean
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      step: 1,
      loadingOriginAddress: false,
      loadingDestinationAddress: false,
      loadingOriginPostalCode: false,
      loadingDestinationPostalCode: false,
      originAddressError: null,
      destinationAddressError: null,
      steps: [
        {
          title: "Origen"
        },
        {
          title: "Destino"
        },
        {
          title: "Datos Adicionales"
        }
      ],
      item: {
        id: 0,
        creation_date: "",
        code: "",
        kg: 0,
        bultos: 0,
        declared_value: 0,
        external_reference: "",
        destination_receiver_name: "",
        destination_address: "",
        destination_reference: "",
        destination_location: "",
        destination_postal_code: null,
        destination_provincia: null,
        destination_telephone: "",
        destination_email: "",
        destination_lat: null,
        destination_lng: null,
        origin_name: "",
        origin_address: "",
        origin_reference: "",
        origin_location: "",
        origin_postal_code: null,
        origin_provincia: null,
        origin_telephone: "",
        origin_email: "",
        origin_lat: null,
        origin_lng: null,
      },
      defaultItem: null,
      provincias: [],
      originPostalCodes: [],
      destinationPostalCodes: []
    };
  },

  watch: {
    dialog() {
      this.step = 1;
      if (this.dialog) {
        this.disabled = this.formBtn == "";

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
        }

        if (this.editItem != null && this.editItem.id > 0) {
          this.item = JSON.parse(JSON.stringify(this.editItem));
        } else {
          if (this.defaultItem != null) this.item = JSON.parse(JSON.stringify(this.defaultItem));
        }
      }
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.loadProvincias();
    this.loadDataOrigin();

  },
  methods: {
    prev() {
      this.step--;
    },
    async nextForm1() {
      const { valid } = await this.$refs.form_step1.validate();

      if (valid) {
        this.step++;
      }
    },
    async nextForm2() {
      const { valid } = await this.$refs.form_step2.validate();

      if (valid) {
        this.step++;
      }
    },
    closeModal() {
      this.$emit('updateDialog', false);
      this.step = 1;
    },
    loadProvincias() {
      provincesService
        .getAllProvince()
        .then((response) => {
          this.provincias = response.result.list;
        })
        .catch((e) => {
          console.log("service provincias error");
        });
    },
    loadOriginPostalCodes(id_provincia) {
      this.item.origin_postal_code = null;
      this.loadingOriginPostalCode = true;

      const body = {
        provincia: {
          id: id_provincia
        }
      }

      postalCodesService
        .getPostalCodes(body)
        .then((response) => {
          this.originPostalCodes = response.result.list;
        })
        .catch((e) => {
          console.log("service postal codes error");
        })
        .finally(() => {
          this.loadingOriginPostalCode = false;
        });

    },
    loadDestinationPostalCodes(id_provincia) {
      this.item.destination_postal_code = null;
      this.loadingDestinationPostalCode = true;

      const body = {
        provincia: {
          id: id_provincia
        }
      }

      postalCodesService
        .getPostalCodes(body)
        .then((response) => {
          this.destinationPostalCodes = response.result.list;
        })
        .catch((e) => {
          console.log("service postal codes error");
        })
        .finally(() => {
          this.loadingDestinationPostalCode = false;
        });

    },
    loadDataOrigin() {
      profileService
        .filter()
        .then((response) => {
          this.defaultItem.origin_name = response.result.name
          this.defaultItem.origin_provincia = { id: response.result.provincia.id, name: response.result.provincia.name }
          this.defaultItem.origin_postal_code = { id: response.result.postal_code.id, code: response.result.postal_code.code }
          this.defaultItem.origin_location = response.result.city
          this.defaultItem.origin_address = response.result.address
          this.defaultItem.origin_reference = response.result.reference
          this.defaultItem.origin_email = response.result.user.email
          this.defaultItem.origin_telephone =response.result.phone
          this.defaultItem.origin_lat = response.result.lat
          this.defaultItem.origin_lng = response.result.lng
        })
        .catch((e) => {
          console.log("service.load shipping error ");
          console.log(e);
        })
    },
    loadPregeoOrigin() {

      if (this.item.origin_address) {
        this.loadingOriginAddress = true;
        this.originAddressError = null;

        const address = {
          address: this.item.origin_address + ', ' + this.item.origin_location + ', '+ this.item.origin_postal_code.code + ', ' + this.item.origin_provincia.name
        }

        this.service
          .pregeo(address)
          .then((response) => {
            this.item.origin_lat = response.result.lat;
            this.item.origin_lng = response.result.lng;
            if (response.status === false) {
              this.originAddressError = response.msg;
            }
          })
          .catch((response) => {
            this.toast.error(response.msg)
          })
          .finally(() => {
            this.loadingOriginAddress = false;
          });
      }
    },
    loadPregeoDestination() {
      if (this.item.destination_address && this.item.destination_location) {
        this.loadingDestinationAddress = true;

        this.destinationAddressError = null;

        const address = {
          address: this.item.destination_address + ',' + this.item.destination_location + ', ' + this.item.destination_postal_code.code + ', ' + this.item.destination_provincia.name
        }

        this.service
          .pregeo(address)
          .then((response) => {
            this.item.destination_lat = response.result.lat;
            this.item.destination_lng = response.result.lng;

            if (response.status === false) {
              this.destinationAddressError = response.msg;
            }
          })
          .catch((response) => {
            this.toast.error(response.msg)
          })
          .finally(() => {
            this.loadingDestinationAddress = false;
          })
      }

    },

    createItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;

        var data = this.item;

        if (this.item.id > 0) {
          this.service
            .update(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se modificó correctamente.");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((response) => {
              this.toast.error(response.msg);
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.service
            .create(data)
            .then((response) => {
              if (response.status) {
                this.toast.success("El " + this.modal_title + " se creó correctamente");
                this.$emit("updateDialog", false);
                this.$emit("loadList");
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.toast.error(response.message);
                } else {
                  this.toast.error(response.msg);
                }
              }
            })
            .catch((e) => {
              this.toast.error(e);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
