<template>
  <GMapMap :center="mapCenter" :zoom="16" :options="mapOptions" style="width: 100%; height: 225px">
    <GMapMarker v-for="(marker, index) in markers" :key="index" :position="marker.position" />
  </GMapMap>
</template>

<script>
export default {
  name: 'App',
  props: {
    lat: { default: null },
    lng: { default: null },
  },
  data() {
    return {
      mapCenter: { lat: parseFloat(-34.6036844), lng: parseFloat(-58.3815591) },
      mapOptions: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        disableDefaultUi: true,
      },
      markers: [
        {
          position: {
            lat: parseFloat(-34.6036844),
            lng: parseFloat(-58.3815591),
          },
        },
      ],
    };
  },
  watch: {
    lat(newLat) {
      this.updateMarkerPosition(newLat, this.lng);
    },
    lng(newLng) {
      this.updateMarkerPosition(this.lat, newLng);
    },
  },
  mounted() {
    this.updateMarkerPosition(this.lat, this.lng)
  },
  methods: {
    updateMarkerPosition(lat, lng) {
      let finalLat = lat !== null ? parseFloat(lat) : this.mapCenter.lat;
      let finalLng = lng !== null ? parseFloat(lng) : this.mapCenter.lng;

      this.markers = [
        {
          position: {
            lat: finalLat,
            lng: finalLng,
          },
        },
      ];
      this.mapCenter = { lat: finalLat, lng: finalLng };
    },
  },
};
</script>
