// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as labsComponents from "vuetify/labs/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

import { es } from "vuetify/locale";

const vuetify = createVuetify({
  components: {
    ...components,
    ...labsComponents,
  },
  locale: {
    locale: "es",
    fallback: "es",
    messages: { es },
  },
  directives,
  icons: {
    defaultSet: "mdi", // This is already the default value - only for display purposes
  },
});

export default vuetify;
