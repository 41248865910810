const STATE_PENDING = 1;//Pendiente 
const STATE_QUOTING = 2;//Cotizada
const STATE_ACEPTED = 3;//Aceptada
const STATE_PENDIENTE_RETIRO = 4;//Pendiente de retiro
const STATE_COLECTADA = 5;//Colectada
const STATE_FINALIZADA = 6;//Finalizada  

const SUBSTATE_VENCIDA = 1;//Cotizacion Vencida
const SUBSTATE_RECHAZADA = 2;//Cotizacion rechazada por el cliente o logistica
const SUBSTATE_PENDIENTE_ACEPTACION = 3;//Pendiente de aceptacion
const SUBSTATE_PENDIENTE_PAGO = 4;//Pendiente de pago
const SUBSTATE_PAGO_APROBADO = 5;//Pago aprobado
const SUBSTATE_PAGO_RECHAZADO = 6;//Pago pendiente
const SUBSTATE_PAGO_EN_PROCESO = 7;//Pago procesando
const SUBSTATE_LIQUIDADA = 8;//Liquidada al transportista
const SUBSTATE_CANCELADA = 9;//Cancelada

export default {
    STATE_PENDING: STATE_PENDING,
    STATE_QUOTING: STATE_QUOTING,
    STATE_ACEPTED:STATE_ACEPTED,
    STATE_PENDIENTE_RETIRO:STATE_PENDIENTE_RETIRO,
    STATE_COLECTADA:STATE_COLECTADA,
    STATE_FINALIZADA:STATE_FINALIZADA,
    SUBSTATE_VENCIDA:SUBSTATE_VENCIDA,
    SUBSTATE_RECHAZADA:SUBSTATE_RECHAZADA,
    SUBSTATE_PENDIENTE_ACEPTACION:SUBSTATE_PENDIENTE_ACEPTACION,
    SUBSTATE_PENDIENTE_PAGO:SUBSTATE_PENDIENTE_PAGO,
    SUBSTATE_PAGO_APROBADO:SUBSTATE_PAGO_APROBADO,
    SUBSTATE_PAGO_RECHAZADO:SUBSTATE_PAGO_RECHAZADO,
    SUBSTATE_PAGO_EN_PROCESO:SUBSTATE_PAGO_EN_PROCESO,
    SUBSTATE_LIQUIDADA:SUBSTATE_LIQUIDADA,
    SUBSTATE_CANCELADA:SUBSTATE_CANCELADA
  };
  