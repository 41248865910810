<template>
  <div>
    <table :class="width" class="table-container">
      <thead>
        <tr class="table-header mb-4">
          <th v-for="(item, i) in header" :key="i"
            :class="['table-cell', `text-${item.align || 'start'}`, { 'sortable': item !== sortBy, 'sorted-asc': item === sortBy && !sortDesc, 'sorted-desc': item === sortBy && sortDesc }]"
            @click="item.checkbox ? null : toggleSort(item)">
            <v-checkbox class="check-table" v-if="item.checkbox" :model-value="selectedAllParent"
              @change="$emit('handleSelectedAllItems')" />

            {{ item.name }}
            <span v-if="item.name === sortBy" class="sort-indicator">
              <i class="mdi mdi-chevron-up" v-if="!sortDesc"></i>
              <i class="mdi mdi-chevron-down" v-if="sortDesc"></i>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading" class="table-row mb-2">
          <td v-for="(item, i) in header" :key="i" class="table-cell">
            <v-skeleton-loader type="text" width="80%"></v-skeleton-loader>
          </td>
        </tr>
        <tr v-else-if="list && list.length === 0" class="table-row mb-2">
          <td :colspan="header.length" class="table-cell">
            <div class="error-message">No hay elementos</div>
          </td>
        </tr>
        <tr v-else v-for="(item, i) in list" :key="i" class="table-row mb-2">
          <slot name="rows" v-bind:item="item" class="table-cell"></slot>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
export default {
  name: "RowTable",
  props: {
    width: { Type: String, default: "col-md-12" },
    list: Array,
    header: Array,
    loading: Boolean,
    toggleSort: Function,
    sortBy: String,
    sortDesc: Boolean,
    selectedAllParent: Boolean,
    handleSelectedAllItems: Function
  },
  data() {
    return {
    };
  },
  computed: {},
  methods: {},
};
</script>
