<template>
  <v-col :class="['status-container', colorClass]" class="elevation-0 pt-4 pb-4 mt-4">
    <v-row class="justify-center align-center">
      <v-col md="12" class="d-flex align-center justify-center">
        <div class="text-center align-center">
          <div>
            <font-awesome-icon :icon="icon" :class="iconClass" class="status-icon" />
          </div>
          <div>
            <v-label class="status-title mt-3">{{ title }}</v-label>
          </div>
          <div>
            <v-label class="status-subtext" v-html="message"></v-label>
          </div>
        </div>
      </v-col>

      <v-col md="12" class="d-flex align-center justify-center " v-if="status === 'failure'">
          <Button  flat :loading="loading" text
            class="bg-button-pay btn text-capitalize btn-modal" rounded="xl" height="50"
            label="Intentar pagar nuevamente" @click="$emit('handlePay')" width="300"></Button>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  components: { Button },
  props: {
    status: {
      type: String,
      required: true,
      validator: value => ['success', 'failure', 'pending', "error"].includes(value),
    },
    handlePay: Function,
    loading: Boolean,
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  computed: {
    colorClass() {
      switch (this.status) {
        case 'success':
          return 'bg-success';
        case 'failure':
          return 'bg-error';
        case 'error':
          return 'bg-error';
        case 'pending':
          return 'bg-pending';
        default:
          return '';
      }
    },
    iconClass() {
      return 'status-icon-' + this.status;
    },
    icon() {
      switch (this.status) {
        case 'success':
          return ['fas', 'check-circle'];
        case 'failure':
          return ['fas', 'circle-xmark'];
        case 'error':
          return ['fas', 'circle-xmark'];
        case 'pending':
          return ['fas', 'clock'];
        default:
          return ['fas', 'question-circle'];
      }
    },
    methods: { }
  }
};
</script>