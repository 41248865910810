<template>
  <v-layout class="container-modal">
    <v-dialog :modelValue="dialog" max-width="1100px" transition="dialog-bottom-transition">
      <v-card style="height: 100%;">
        <v-form @submit.prevent ref="form" v-model="valid" v-on:submit.prevent="updateItem">
          <v-card-title class="p-3 header-modal mb-4">
            <span class="headline text-white">Mi Perfil</span>
          </v-card-title>
          <v-container fluid>
            <v-row>
              <v-col cols="4" md="4" sm="12">
                <div class="container-avatar d-flex text-start align-center">
                  <v-avatar color="surface-variant" size="80">
                    <input type="file" ref="fileInput" style="display: none" @change="previewImage">
                    <v-icon v-if="item.profile_picture_url == null && imageUrl == null" @click="openFileInput">mdi-camera</v-icon>
                    <v-img v-if="item.profile_picture_url != null && imageUrl == null" :src="item.profile_picture_url" @click="openFileInput" alt="Avatar Image" />
                    <v-img v-if="imageUrl != null" :src="imageUrl" @click="openFileInput" alt="Avatar Image" />

                  </v-avatar>
                  <div class="ms-2">
                    <div>
                      <v-label class="text-avatar text-white mb-0">{{ item.business_name }}</v-label>
                    </div>
                    <div>
                      <v-label class="subtext-avatar text-white mb-0">{{ item.email }}</v-label>
                    </div>
                  </div>
                </div>
                <v-card class="mx-auto" tile style="height: 100%; max-height: 345px;">
                  <v-list class="pt-0">
                    <v-list-item-group v-model="selectedItem" color="primary" mandatory>
                      <v-list-item v-for="(item, i) in items" :key="i" @click="item.func" :active="selectedItem === i"
                        active-class="list-item-active">
                        <v-list-item-content class="d-flex">
                          <font-awesome-icon class="me-3 mt-1" :icon="item.icon" />
                          <v-list-item-title :class="{ 'font-weight-bold': selectedItem === i }"
                            v-text="item.text"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="8" md="8" sm="12">
                <v-card class="mx-auto">
                  <div v-if="selectedItem == 0">
                    <v-card-text>
                      <div class="row">
                        <div class="col-md-6">
                          <v-label>Nombre y apellido</v-label>
                          <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                            v-model="item.name"></v-text-field>
                          <v-label>Email</v-label>
                          <v-text-field density="compact" :rules="[rules.required, rules.email]" persistent-hint
                            variant="outlined" v-model="item.email" autocomplete="off"></v-text-field>
                          <v-label>Teléfono</v-label>
                          <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                            v-model="item.phone"></v-text-field>
                          <v-label>CUIT</v-label>
                          <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                            v-model="item.cuit"></v-text-field>
                        </div>
                        <div class="col-md-6">
                          <v-label>Razón Social</v-label>
                          <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                            v-model="item.business_name" required></v-text-field>
                          <v-label>Dirección</v-label>
                          <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                            v-model="item.address"></v-text-field>
                          <v-label>Ciudad</v-label>
                          <v-text-field density="compact" :rules="[rules.required]" persistent-hint variant="outlined"
                            v-model="item.city"></v-text-field>
                          <v-label>Provincia</v-label>
                          <v-combobox density="compact" :rules="[rules.requiredComboBox]" persistent-hint item-title="name"
                            item-value="id" v-model="item.provincia" :items="provincias" variant="outlined"
                            autocomplete="off"></v-combobox>
                        </div>
                      </div>
                    </v-card-text>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <Button flat type="button" class="btn text-capitalize text-white me-1 btn-modal" rounded="xl"
                      height="50" text @click="$emit('updateDialog', false)" label="Cerrar" color="error"></Button>
                    <Button flat :loading="loading" text type="submit"
                      class="bg-button-register btn text-capitalize text-white btn-modal" rounded="xl" height="50"
                      label="Editar" v-if="!disabled"></Button>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ValidMixin from "@/mixins/ValidMixin.vue";
import Button from "@/components/Button.vue";
import { profileService } from "@/libs/ws/Public/profileService";
import { provincesService } from "@/libs/ws/Public/provincesService";

export default {
  mixins: [ValidMixin],
  components: { Button },
  props: {
    service: Object,
    dialog: Boolean,
    updateDialog: Function,
  },
  data() {
    return {
      show: false,
      disabled: false,
      loading: false,
      loadingAddress: false,
      errorAddress: false,
      name: "",
      selectedItem: 0,
      items: [
        {
          icon: "user",
          text: "Mis Datos",
          func: this.handleStepMisDatos
        }
      ],
      item: {
        email: "",
        name: "",
        phone: "",
        address: "",
        city: "",
        cuit: "",
        business_name: "",
        provincia: "",
        profile_picture_path: null,
        profile_picture_url: null
      },
      provincias: [],
      imageUrl: null,
      imageFile: null
    };
  },
  mounted() {
    this.loadProvincias();
    this.loadProfile();
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.imageFile = file;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target.result;
        this.updateImageProfile();
      };
      reader.readAsDataURL(file);
    },
    handleStepMisDatos() {
      this.selectedItem = 0;
    },
    loadProvincias() {
      provincesService
        .getAllProvince()
        .then((response) => {
          this.provincias = response.result.list;
        })
        .catch((e) => {
          console.log("service.load provinces profile seller error");
          console.log(e);
        });
    },
    loadProfile() {
      profileService
        .filter()
        .then((response) => {
          if (response.result.provincia != null) {
            this.loadPostalCodes(response.result.provincia.id, false);
          }

          this.item.email = response.result.user.email;
          this.item.name = response.result.name;
          this.item.phone = response.result.phone;
          this.item.address = response.result.address;
          this.item.postal_code = response.result.postal_code || "";
          this.item.city = response.result.city;
          this.item.cuit = response.result.cuit;
          this.item.business_name = response.result.business_name;
          this.item.provincia = response.result.provincia != null ? { id: response.result.provincia.id, name: response.result.provincia.name } : "";
          this.item.lng = response.result.lng;
          this.item.lat = response.result.lat;
          this.item.profile_picture_url = response.result.profile_picture_url;
        })
        .catch((e) => {
          console.log("service.load profile seller error ");
          console.log(e);
        });
    },
    updateItem() {
      if (this.loading) return;

      if (this.valid) {
        this.loading = true;
        var data = this.item;

        this.service
          .update(data)
          .then((response) => {
            if (response.status) {
              this.toast.success("El perfil se modificó correctamente.");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.toast.error(response.message);
              } else {
                this.toast.error(response.msg);
              }
            }
          })
          .catch((response) => {
            this.toast.error(response.msg);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateImageProfile() {
      // if (this.loading || !this.imageFile) return;

      const formData = new FormData();
      formData.append("profile_picture_file", this.imageFile);

      this.loading = true;

      profileService
        .updateProfilePic(formData)
        .then((response) => {
            if (response.status) {
              this.toast.success("La imagen se actualizó correctamente.");
              this.imageUrl = response.result.url;

              this.$emit("updateDialog", false);
              this.loadProfile();
            } else {
              if (Object.prototype.hasOwnProperty.call(response, "message")) {
                this.toast.error(response.message);
              } else {
                this.toast.error(response.msg);
              }
            }
          })
          .catch((response) => {
            this.toast.error(response.msg);
          })
          .finally(() => {
            this.loading = false;
          });
        
    }
  }
};
</script>
